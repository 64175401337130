import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import { cn } from '../../libs/cn';
import ResponsiveIcon from './Icon';
import { iconMap } from '../../theme/Icons';

const buttonVariants = cva(
	'inline-flex text-grey-900 items-center gap-x-2 hover:gap-x-4 transition-all duration-500 justify-center whitespace-nowrap rounded-md focus-visible:outline-none disabled:pointer-events-none rounded-full disabled:bg-none disabled:bg-transparent disabled:text-grey-600 leading-normal',
	{
		variants: {
			variant: {
				icon: 'bg-gradient-primary border border-primary hover:bg-gradient-primary/90 disabled:bg-grey-300',
				default:
					'bg-gradient-primary border border-primary hover:bg-gradient-primary/90 disabled:bg-grey-300 disabled:border-grey-300',
				outline: 'border disabled:border-grey-300',
				link: 'text-primary underline-offset-4 hover:underline',
			},
			size: {
				default: 'py-1.5 px-5 text-base',
				sm: 'py-1.5 px-4 text-sm',
				lg: 'py-3 px-4 sm:px-8 text-lg',
				icon: 'h-11 w-11',
			},
		},
		defaultVariants: {
			variant: 'default',
			size: 'default',
		},
	}
);

export interface ButtonProps
	extends React.ButtonHTMLAttributes<HTMLButtonElement>,
		VariantProps<typeof buttonVariants> {
	asChild?: boolean;
	icon?: React.ReactNode;
	loading?: boolean;
	iconPosition?: 'left' | 'right';
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
	(
		{
			className,
			variant,
			size,
			icon,
			loading = false,
			asChild = false,
			children,
			iconPosition = 'left',
			...props
		},
		ref
	) => {
		const Comp = asChild ? Slot : 'button';
		return (
			<Comp
				className={cn(buttonVariants({ variant, size, className }))}
				ref={ref}
				disabled={loading || props.disabled}
				{...props}
			>
				{loading ? (
					<ResponsiveIcon
						icon={iconMap.spinner}
						className="motion-safe:animate-spin h-5 w-5 my-1"
					/>
				) : (
					<>
						{icon && iconPosition === 'left' && icon}
						{children}
						{icon && iconPosition === 'right' && icon}
					</>
				)}
			</Comp>
		);
	}
);
Button.displayName = 'Button';

export { Button, buttonVariants };
