import * as React from 'react';
import { cn } from '../../libs/cn';
import { IconPropsType } from '../../theme/Icons';

export interface InputProps
	extends React.InputHTMLAttributes<HTMLInputElement> {
	rootClassName?: string;
	icon?: React.FC<IconPropsType>;
	placeholder?: string;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
	(
		{ className, rootClassName, type, placeholder, icon: Icon, ...props },
		ref
	) => {
		return (
			<div className={cn(`flex items-center relative`, rootClassName)}>
				<input
					type={type}
					className={cn(
						'flex w-full rounded-full border border-input bg-transparent px-4 py-2 text-base transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-grey-600 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50',
						Icon && 'pl-10',
						className
					)}
					ref={ref}
					placeholder={placeholder}
					{...props}
				/>
				{Icon && (
					<div className="absolute left-2">
						<Icon color="black" />
					</div>
				)}
			</div>
		);
	}
);

Input.displayName = 'Input';

export { Input };
