import { type SagaIterator } from '@redux-saga/core';
import { call, put, takeEvery } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

import { authActions } from './slice';
import { loginApi, verifyLoginApi } from './api';

const mockAccessCode = 'some code';
const mockUser = { username: 'admin', email: 'admin@gmail.com' };

// Worker Sagas
export function* loginWorker(
	action: PayloadAction<{ email: string; password: string }>
): SagaIterator {
	try {
		yield call(loginApi, action.payload);
		yield put(authActions.loginSuccess(mockAccessCode));
	} catch (error: unknown) {
		yield put(authActions.loginFailure('Failed to log in'));
	}
}

export function* verifyLoginWorker(
	action: PayloadAction<string[]>
): SagaIterator {
	try {
		yield call(verifyLoginApi, action.payload);
		yield put(authActions.verificationSuccess(mockUser));
	} catch (error: unknown) {
		yield put(authActions.verificationFailure('Failed to verify'));
	}
}

// Watcher Saga
function* authWatcherSaga(): SagaIterator {
	yield takeEvery(authActions.loginRequest, loginWorker);
	yield takeEvery(authActions.verificationRequest, verifyLoginWorker);
}

export default authWatcherSaga;
