import React from 'react';
import {
	createBrowserRouter,
	NonIndexRouteObject,
	Navigate,
} from 'react-router-dom';
import DashboardLayout from '../components/templates/DashboardLayout';
import { iconMap } from '../theme/Icons';
import Dashboard from '../components/pages/DashboardPage';
import TransactionsPage from '../components/pages/TransactionsPage';
import LoginPage from '../components/pages/auth/LoginPage';
import VerifyCodePage from '../components/pages/auth/VerifyCodePage';
import AuthLayout from '../components/templates/AuthLayout';
import AuthAndVerificationGuard from '../guards/AuthAndVerificationGuard';
import RequireAuth from '../guards/AuthGuard';
import WalletsPage from '../components/pages/wallets/WalletsPage';
import WalletDetailsPage from '../components/pages/wallets/WalletDetailsPage';

export type RouteHandle = {
	label?: string;
	pageTitle?: string;
	position?: string;
};

export interface CustomRouteObject
	extends Omit<NonIndexRouteObject, 'children'> {
	element: React.ReactNode;
	path?: string;
	icon?: React.ElementType;
	handle?: (params?: any) => RouteHandle;
	children?: CustomRouteObject[];
}

const authRoutes = [
	{
		path: '/auth/login',
		element: <LoginPage />,
	},
	{
		path: '/auth/verify-code',
		element: (
			<RequireAuth>
				<VerifyCodePage />
			</RequireAuth>
		),
	},
];

const sidebarRoutes = [
	{
		icon: iconMap.dashboard,
		path: '/dashboard',
		element: <Dashboard />,
		handle: () => ({
			label: 'Dashboard',
			pageTitle: 'Dashboard',
			position: 'top',
		}),
	},
	{
		icon: iconMap.transactions,
		path: '/transactions',
		element: <TransactionsPage />,
		handle: () => ({
			label: 'Transactions',
			pageTitle: 'Transactions',
			position: 'top',
		}),
	},
	{
		icon: iconMap.recipients,
		path: '/whitelist',
		element: <div>Whitelist page</div>,
		handle: () => ({
			label: 'Whitelist',
			pageTitle: 'Whitelist',
			position: 'top',
		}),
	},
	{
		icon: iconMap.wallets,
		path: '/my-wallets',
		element: <WalletsPage />,
		handle: () => ({
			label: 'My Wallets',
			pageTitle: 'My Wallets',
			position: 'top',
		}),
	},
	{
		icon: iconMap.settings,
		path: '/my-settings',
		element: <div>My Settings page</div>,
		handle: () => ({
			label: 'My Settings',
			pageTitle: 'My Settings',
			position: 'top',
		}),
	},
	{
		icon: iconMap.support,
		path: '/support',
		element: <div>Support page</div>,
		handle: () => ({
			label: 'Support',
			pageTitle: 'Support',
			position: 'bottom',
		}),
	},
];

const routes: CustomRouteObject[] = [
	{
		path: '/',
		element: <Navigate to="/dashboard" />,
	},
	{
		element: <AuthLayout />,
		children: [...authRoutes],
	},
	{
		element: (
			<AuthAndVerificationGuard>
				<DashboardLayout routes={sidebarRoutes} />
			</AuthAndVerificationGuard>
		),
		children: [
			...sidebarRoutes,
			// other routes that won't be displayed on the sidebar
			{
				path: '/wallets/:id',
				element: <WalletDetailsPage />,
				handle: ({ id }) => {
					return {
						pageTitle: `Monitored Wallets / Wallet ${id}`,
					};
				},
			},
		],
	},
	{
		path: '*',
		element: <div>Page not found</div>,
	},
];

export const router = createBrowserRouter(routes);
