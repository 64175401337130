import { CellContext, ColumnDef } from '@tanstack/react-table';

import { Transaction, WalletDataType } from '../../../types/transaction';
import {
	getDateTimeCell,
	getRiskLevelCell,
	getTotalAmountCell,
	WalletCodeCell,
} from '../../organisms/Table/baseConfig';
import { shortenString } from '../../../utils/string';
import ResponsiveIcon from '../../atoms/Icon';
import { iconMap } from '../../../theme/Icons';

const getTypeCell: <T>(
	props: CellContext<T, string | Record<string, string>>
) => JSX.Element = (props) => {
	const value = props.getValue<string>();

	return (
		<div className="flex gap-2 items-center">
			{value === 'in' && (
				<ResponsiveIcon
					icon={iconMap.plus}
					colors={['#1CE7DC', '#1CE7AC', '#1CE7A2']}
					className="w-3.5 h-3.5"
				/>
			)}

			{value === 'out' && (
				<ResponsiveIcon
					icon={iconMap.minus}
					colors={['#E15136', '#EC4B2E', '#F04121']}
					className="w-3.5 h-2"
				/>
			)}
			<span className="capitalize">{value}</span>
		</div>
	);
};

export const historyColumns: (ColumnDef<
	Transaction,
	string | Record<string, string>
> & {
	style?: any;
})[] = [
	{
		accessorKey: 'dateTime',
		header: 'Date & Time',
		cell: getDateTimeCell,
		size: 130,
		enableSorting: true,
	},
	{
		accessorKey: 'from',
		header: 'From',
		cell: (props) => <WalletCodeCell {...props.getValue<WalletDataType>()} />,
		size: 80,
		enableSorting: true,
	},
	{
		accessorKey: 'to.code',
		header: 'To',
		cell: (props) => <WalletCodeCell code={props.getValue<string>()} />,
		size: 80,
		enableSorting: true,
	},
	{
		accessorKey: 'type',
		header: 'Type',
		cell: getTypeCell,
		size: 80,
		enableSorting: true,
	},
	{
		accessorKey: 'hash',
		header: 'Hash#',
		cell: (props) => {
			const value = props.getValue<string>();
			return <span className="underline">{shortenString(value)}</span>;
		},
		enableSorting: true,
		size: 100,
	},
	{
		accessorKey: 'totalAmount',
		header: () => <span>Total Volume</span>,
		cell: getTotalAmountCell,
		enableSorting: true,
		size: 100,
	},
	{
		accessorKey: 'riskLevel',
		header: () => <span>Risk Level</span>,
		cell: getRiskLevelCell,
		enableSorting: true,
		size: 100,
	},
	{
		accessorKey: 'riskFactor',
		header: () => <span className="whitespace-nowrap">Risk Factor</span>,
		cell: (props) => {
			const value = props.getValue<string>();
			return <span>{value}</span>;
		},
		size: 120,
		enableSorting: true,
	},
];
