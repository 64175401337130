import { cn } from '../../../../libs/cn';
import { capitalizeFirstLetter } from '../../../../utils/string';
import { iconMap } from '../../../../theme/Icons';
import ResponsiveIcon from '../../../atoms/Icon';

type BaseLegendProps = {
	payload: any[];
	groupStyle?: string;
};

const BaseLegend: React.FC<BaseLegendProps> = ({ payload, groupStyle }) => {
	const hasGroupField = payload.some(
		(entry) => entry.payload.group !== undefined
	);

	const statusGroups = hasGroupField
		? payload.reduce((acc, cur) => {
				if (!acc[cur.payload.group]) {
					acc[cur.payload.group] = [];
				}
				acc[cur.payload.group].push(cur);
				return acc;
			}, {})
		: { default: payload };

	return (
		<div className="flex flex-wrap flex-col justify-between h-full gap-3 sm:gap-5 -mt-4">
			{Object.keys(statusGroups).map((groupName) => {
				return (
					<div key={groupName} className="flex flex-col items-start gap-2">
						{groupName !== 'default' && (
							<span className="text-gray-600 capitalize text-sm">
								{groupName}
							</span>
						)}
						<div
							className={cn(
								'flex h-full gap-2 sm:gap-4',
								groupName !== 'default' && 'flex-col sm:flex-row',
								groupStyle
							)}
						>
							{statusGroups[groupName].map((entry: any, index: number) => {
								const data = entry?.payload;

								return (
									<div key={`item-${index}`} className="flex gap-2">
										<div className="flex items-center">
											<div
												className="w-5 h-5 mr-2 rounded-xs"
												style={{
													backgroundImage: `var(--gradient-${data.code.toLowerCase()})`,
												}}
											/>
											<p className="flex gap-2 items-center">
												{entry?.value === 'Critical risk' && ( // can use enum instead of string
													<ResponsiveIcon icon={iconMap.warn} />
												)}
												{capitalizeFirstLetter(data?.name)}{' '}
												<span className="font-bold">{data?.value}</span>
											</p>
										</div>
									</div>
								);
							})}
						</div>
					</div>
				);
			})}
		</div>
	);
};

export default BaseLegend;
