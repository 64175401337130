import { type SagaIterator } from '@redux-saga/core';
import { call, put, takeLatest } from 'redux-saga/effects';

import { walletsActions } from './slice';
import { getWalletsApi } from './api';

// Worker Sagas
function* fetchWalletsSaga(): SagaIterator {
	try {
		const response = yield call(getWalletsApi);
		yield put(walletsActions.fetchWalletsSuccess(response.data));
	} catch (error) {
		yield put(walletsActions.fetchWalletsFailure('Failed to load wallets'));
	}
}

// Watcher Saga
function* walletWatcherSaga(): SagaIterator {
	yield takeLatest(walletsActions.fetchWalletsRequest.type, fetchWalletsSaga);
}

export default walletWatcherSaga;
