import { _wallets } from '../../_mock/map/_wallets';

export const getWalletsApi = () => {
	return new Promise((resolve) => {
		const mockData = _wallets;

		setTimeout(() => {
			resolve({ data: mockData });
		}, 1000);
	});
};
