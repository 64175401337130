import { PayloadAction } from '@reduxjs/toolkit';
import { type SagaIterator } from '@redux-saga/core';
import { call, put, takeLatest } from 'redux-saga/effects';

import { walletActions } from './slice';
import { getWalletByIdApi, getTransactionsByWalletIdApi } from './api';

// Worker Sagas
function* fetchWalletByIdSaga(action: PayloadAction<number>): SagaIterator {
	try {
		const response = yield call(getWalletByIdApi, action.payload);
		yield put(walletActions.fetchWalletByIdSuccess(response.data));
	} catch (error) {
		yield put(
			walletActions.fetchWalletByIdFailure(
				`Failed to load wallet ID ${action.payload}`
			)
		);
	}
}

function* fetchTransactionsByWalletIdSaga(
	action: PayloadAction<number>
): SagaIterator {
	try {
		const response = yield call(getTransactionsByWalletIdApi, action.payload);
		yield put(walletActions.fetchTransactionsByWalletIdSuccess(response.data));
	} catch (error) {
		yield put(
			walletActions.fetchTransactionsByWalletIdFailure(
				`Failed to load transactions by wallet ID ${action.payload}`
			)
		);
	}
}

// Watcher Saga
function* walletWatcherSaga(): SagaIterator {
	yield takeLatest(
		walletActions.fetchWalletByIdRequest.type,
		fetchWalletByIdSaga
	);
	yield takeLatest(
		walletActions.fetchTransactionsByWalletIdRequest,
		fetchTransactionsByWalletIdSaga
	);
}

export default walletWatcherSaga;
