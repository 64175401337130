import { iconMap } from '../../theme/Icons';
import { Button } from '../atoms/Button';
import ResponsiveIcon from '../atoms/Icon';

const GuaranteePanel: React.FC = () => {
	return (
		<div className="p-5 xl:mb-10 bg-gradient-primary flex flex-col gap-4 text-center items-center rounded-r-md ml-[-16px] shadow-lg">
			<div className="flex gap-1">
				<ResponsiveIcon icon={iconMap.star} />
				<ResponsiveIcon icon={iconMap.star} />
				<ResponsiveIcon icon={iconMap.star} />
			</div>

			<div className="flex flex-col gap-2">
				<p className="text-5xl font-bold">100%</p>
				<p className="text-lg font-bold leading-6">Money Back Guarantee</p>
			</div>

			<div className="flex gap-1">
				<ResponsiveIcon icon={iconMap.star} />
				<ResponsiveIcon icon={iconMap.star} />
				<ResponsiveIcon icon={iconMap.star} />
			</div>

			<Button size={'sm'} className="bg-white" onClick={() => {}}>
				Learn More
			</Button>
		</div>
	);
};

export default GuaranteePanel;
