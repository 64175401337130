import { BasePieChart } from '../../organisms/Chart/PieChart';
import BaseCard from '../../organisms/BaseCard';
import { Button } from '../../atoms/Button';
import { getChartBgColor } from '../../../utils/getChartColor';

const TransactionsInRisk: React.FC<{ data: any[] }> = ({ data }) => {
	const updatedRiskData = data.map((item) => {
		const { color } = getChartBgColor(item?.code);
		return { ...item, color };
	});

	return (
		<BaseCard
			title="Transaction at Risk"
			cardContentStyle="h-[240px]"
			Footer={
				<Button onClick={() => {}} variant={'outline'} size={'sm'}>
					See Report
				</Button>
			}
		>
			{data && <BasePieChart data={updatedRiskData} />}
		</BaseCard>
	);
};

export default TransactionsInRisk;
