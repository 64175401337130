import { useParams } from 'react-router-dom';

import { Card, CardContent, CardFooter } from '../../atoms/Card';
import useWalletService from '../../../controllers/wallet/service';
import { useEffect } from 'react';
import { Badge } from '../../atoms/Badge';
import { riskLevelColorMap } from './WalletsPage';
import ResponsiveIcon from '../../atoms/Icon';
import { iconMap } from '../../../theme/Icons';
import { Button } from '../../atoms/Button';
import TransactionHistoryTable from '../../specialized/wallet/TransactionHistoryTable';
import { historyColumns } from '../../specialized/wallet/transactionHistoryColumns';
import { Progress } from '../../atoms/Progress';

import { cn } from '../../../libs/cn';

const WalletDetailsPage: React.FC = () => {
	const {
		getWalletById,
		getTransactionsByWalletId,
		data: { walletById, transactions },
		loading,
		error,
	} = useWalletService();

	const { id } = useParams();

	useEffect(() => {
		if (id) {
			getWalletById(Number(id));
			getTransactionsByWalletId(Number(id));
		}
	}, [getTransactionsByWalletId, getWalletById, id]);

	if (loading.walletById || loading.transactions) {
		return (
			<div className="flex justify-center items-center min-h-[600px]">
				<Progress />
			</div>
		);
	}

	return (
		<div className="gap-4 h-[90vh] flex flex-col xl:grid grid-cols-4">
			<Card className="col-span-1 flex flex-col justify-between">
				<CardContent className="gap-8 flex flex-col">
					<div className="flex justify-between items-center gap-2 flex-col sm:flex-row flex-wrap">
						<p className="text-xl font-bold">{walletById?.name}</p>
						<div className="flex items-center gap-2">
							<p className="font-bold">Risk Level</p>
							<Badge variant={walletById?.riskLevel}>
								{walletById?.riskLevel}
							</Badge>
						</div>
					</div>

					<p className="truncate max-w-80">{walletById?.address}</p>

					{walletById?.riskFactors && walletById?.riskFactors.length > 0 && (
						<div className="flex gap-2 items-center flex-wrap">
							<p className="font-bold">Risk Factors</p>
							<ul className="flex gap-2 flex-wrap">
								{walletById?.riskFactors.map((factor, index) => {
									const factorBg =
										riskLevelColorMap[walletById?.riskLevel] || 'bg-gray-100';

									return (
										<Badge
											key={index}
											className={cn('text-black px-2 py-0 min-w-20', factorBg)}
										>
											{factor}
										</Badge>
									);
								})}
							</ul>
						</div>
					)}

					<div>
						{walletById?.cryptocurrencies.map((item) => (
							<div
								key={item?.code}
								className="flex border-b border-grey-600 pb-2 pt-4 w-full flex-col sm:flex-row sm:items-center justify-between flex-wrap"
							>
								<div className="flex items-center w-full sm:w-auto">
									<div className="bg-grey-300 p-2 rounded-full w-fit mr-2">
										<ResponsiveIcon
											icon={iconMap[item.code.toLowerCase()]}
											className="w-5 h-5"
										/>
									</div>
									<div className="flex justify-between items-center sm:w-auto gap-2">
										<span className="flex-shrink-0 min-w-[120px]">
											{item?.name} {item?.code}
										</span>
										<span className="font-bold text-xl">{item?.amount}</span>
									</div>
								</div>

								<div className="flex items-center gap-6 mt-4 sm:mt-0 sm:ml-auto justify-end">
									<Button
										disabled={!item.canSend}
										iconPosition="right"
										icon={
											<ResponsiveIcon
												icon={iconMap.arrow}
												className="w-4 h-5"
											/>
										}
									>
										Go to Send
									</Button>
								</div>
							</div>
						))}
					</div>
				</CardContent>

				<CardFooter className="justify-end">
					<Button
						size={'icon'}
						variant={'icon'}
						icon={<ResponsiveIcon icon={iconMap.delete} className="w-4 h-5" />}
						className="bg-transparent border-none"
					/>
				</CardFooter>
			</Card>

			<Card className="col-span-3 sm:px-2">
				<TransactionHistoryTable
					data={transactions}
					isLoading={loading.transactions}
					columns={historyColumns}
					error={error.transactions}
				/>
			</Card>
		</div>
	);
};

export default WalletDetailsPage;
