import { useMemo } from 'react';

import { Badge } from '../atoms/Badge';

import { reorderProperties } from '../../utils/object';

type StatsBarProps = {
	data: any[];
};

const RiskLevelStats: React.FC<StatsBarProps> = ({ data }) => {
	const countRiskLevels = (arr: any[], field: string) => {
		const riskLevelCounts: Record<string, number> = {
			critical: 0,
			high: 0,
			medium: 0,
			low: 0,
		};

		arr.forEach((el) => {
			const riskLevel = el[field];
			if (riskLevelCounts[riskLevel] === undefined) {
				riskLevelCounts[riskLevel] = 0;
			}
			riskLevelCounts[riskLevel]++;
		});

		return riskLevelCounts;
	};

	const riskLevelCount = useMemo(
		() => countRiskLevels(data, 'riskLevel'),
		[data]
	);

	const riskLevels: any = useMemo(
		() =>
			reorderProperties(riskLevelCount, ['critical', 'high', 'medium', 'low']),
		[riskLevelCount]
	);

	return (
		<div className="flex items-center gap-5 flex-col md:flex-row">
			<span className="font-bold">Filter</span>

			<div className="flex gap-1 sm:gap-4 bg-grey-50 items-center px-4 py-2 rounded-full flex-col sm:flex-row">
				<span className="font-bold text-nowrap">Risk level</span>
				<div className="flex gap-2 2xl:gap-4 flex-wrap justify-center lg:justify-start">
					{Object.entries(riskLevels).map(([key, value]) => (
						<Badge key={key} variant={key as any} className="gap-2" hideIcon>
							<span className="font-bold capitalize">{key}</span>{' '}
							{!value ? '-' : (value as number)}
						</Badge>
					))}
				</div>{' '}
			</div>
		</div>
	);
};

export { RiskLevelStats };
