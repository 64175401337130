import { cn } from '../../libs/cn';
import {
	Card,
	CardContent,
	CardFooter,
	CardHeader,
	CardTitle,
} from '../atoms/Card';

export type BaseCardProps = {
	children: React.ReactNode;
	className?: string;
	Footer?: React.ReactNode;
	cardStyle?: string;
	cardHeaderStyle?: string;
	cardTitleStyle?: string;
	cardContentStyle?: string;
	cardFooterStyle?: string;
	title?: string | JSX.Element;
	subtitle?: string;
};

const BaseCard: React.FC<BaseCardProps> = ({
	children,
	cardStyle,
	cardHeaderStyle,
	cardTitleStyle,
	cardContentStyle,
	cardFooterStyle,
	title,
	subtitle,
	Footer,
}) => {
	return (
		<Card className={cn('flex flex-col gap-5 h-full', cardStyle)}>
			<CardHeader className={cn('gap-1', cardHeaderStyle)}>
				{title && <CardTitle className={cardTitleStyle}>{title}</CardTitle>}
				{subtitle && <p>{subtitle}</p>}
			</CardHeader>

			<CardContent className={cardContentStyle}>{children}</CardContent>

			{Footer && (
				<CardFooter
					className={cn(
						'justify-end lg:justify-end items-end',
						cardFooterStyle
					)}
				>
					{Footer}
				</CardFooter>
			)}
		</Card>
	);
};

export default BaseCard;
