import { type Action, combineReducers } from '@reduxjs/toolkit';
import dashboardSlice from '../controllers/dashboard/slice';
import authSlice from '../controllers/auth/slice';
import walletsSlice from '../controllers/wallets/slice';
import walletSlice from '../controllers/wallet/slice';
import { RootState } from './config.store';

const appReducer = combineReducers({
	transactions: dashboardSlice,
	auth: authSlice,
	wallets: walletsSlice,
	wallet: walletSlice,
});

const rootReducer = (
	state: RootState,
	action: Action
): ReturnType<typeof appReducer> => {
	return appReducer(state, action);
};

export default rootReducer;
