import { useState } from 'react';
import { CellContext, ColumnDef, ColumnMeta } from '@tanstack/react-table';

import { shortenString } from '../../../utils/string';
import {
	BackGuarantee,
	Transaction,
	TransactionStatus,
	WalletDataType,
} from '../../../types/transaction';
import { Status, StatusProps } from '../../atoms/Status';
import { Guarantee, GuaranteeProps } from '../../atoms/Guarantee';
import ResponsiveIcon from '../../atoms/Icon';
import { Button } from '../../atoms/Button';
import { RenderExpandedRowContent } from './TransactionTable';
import {
	FilterOptionsType,
	FilterTypeEnum,
	TypeRangeFilterData,
} from '../../organisms/Table/BaseTable';

import {
	getDateTimeCell,
	getRiskLevelCell,
	getTotalAmountCell,
	WalletCodeCell,
} from '../../organisms/Table/baseConfig';
import { iconMap } from '../../../theme/Icons';

import { cn } from '../../../libs/cn';
import { _cryptocurrencies } from '../../../_mock';

const StatusCell: <T>(
	props: CellContext<T, string | Record<string, string>>
) => JSX.Element = (props) => {
	const value = props.getValue<string>();
	const status = value.toLocaleLowerCase()?.replace(' ', '');

	const [transactionStatus, setTransactionStatus] = useState(status);

	const handlePause = () => {
		setTransactionStatus(TransactionStatus.paused);
	};

	const handleResend = () => {
		setTransactionStatus(TransactionStatus.transferring);
	};

	return (
		<div className="flex justify-between transition-all">
			{value === TransactionStatus.unknown ? (
				<div className="flex items-center group gap-1">
					<ResponsiveIcon
						icon={iconMap.hourglass}
						className="h-5 w-5 transform transition-transform duration-500 ease-in-out group-hover:rotate-180"
					/>
					<p>Waiting for User Action</p>
				</div>
			) : transactionStatus === TransactionStatus.paused ? (
				<Button
					variant={'outline'}
					className="px-3.5 hover:gap-2"
					onClick={handleResend}
					icon={<ResponsiveIcon icon={iconMap.refresh} />}
					iconPosition="right"
				>
					Resend
				</Button>
			) : (
				<div
					className={cn(
						'flex gap-1 items-center capitalize',
						transactionStatus === TransactionStatus.transferring &&
							'flex-col-reverse items-start group-hover:opacity-50'
					)}
				>
					<Status variant={transactionStatus as StatusProps['variant']} />
					{transactionStatus === TransactionStatus.transferring
						? `${transactionStatus}...`
						: transactionStatus}
				</div>
			)}
			{transactionStatus === TransactionStatus.transferring && (
				<div className="hidden group-hover:block">
					<Button
						variant={'outline'}
						className="px-3.5 hover:gap-2"
						onClick={handlePause}
					>
						Pause
						<ResponsiveIcon icon={iconMap.pause} />
					</Button>
				</div>
			)}
		</div>
	);
};

const getGuaranteeCell: <T>(
	props: CellContext<T, string | Record<string, string>>
) => JSX.Element = (props) => {
	const value = props.getValue<string>();

	return (
		<div>
			<div className={cn('flex items-center gap-3 capitalize')}>
				{value}
				<Guarantee variant={value as GuaranteeProps['variant']} />
			</div>
			{value === BackGuarantee.yes && (
				<div className="text-sm flex gap-1">
					<span>More Info</span>
					<ResponsiveIcon icon={iconMap.file} className="w-5 h-5" />
				</div>
			)}
		</div>
	);
};

///////////////

export type ColumnType = ColumnDef<
	Transaction,
	string | Record<string, string>
> & {
	style?: ColumnStyleType | undefined;
};

export type ColumnStyleType = {
	width?: string | number;
};

export const dashboardColumns: (ColumnDef<
	Transaction,
	string | Record<string, string>
> & {
	style?: ColumnStyleType;
})[] = [
	{
		accessorKey: 'dateTime',
		header: 'Date & Time',
		cell: getDateTimeCell,
		size: 120,
	},
	{
		accessorKey: 'from',
		header: 'From',
		cell: (props) => <WalletCodeCell {...props.getValue<WalletDataType>()} />,
		size: 80,
	},
	{
		accessorKey: 'to.code',
		header: 'To',
		cell: (props) => <WalletCodeCell code={props.getValue<string>()} />,
		size: 80,
	},
	{
		accessorKey: 'hash',
		header: 'Hash#',
		cell: (props) => {
			const value = props.getValue<string>();
			return <span className="underline">{shortenString(value)}</span>;
		},
		size: 100,
	},
	{
		accessorKey: 'totalAmount',
		header: () => <span className="whitespace-nowrap">Total Amount</span>,
		cell: getTotalAmountCell,
		size: 100,
	},
	{
		accessorKey: 'riskLevel',
		header: () => <span className="block w-24">Risk Level</span>,
		cell: getRiskLevelCell,
		size: 100,
	},
	{
		accessorKey: 'status',
		header: () => <span className="block">Status</span>,
		cell: StatusCell,
		size: 220,
	},
	{
		accessorKey: 'moneyBackGuarantee',
		header: () => <div className="flex-1 w-16">Money Back Guarantee</div>,
		cell: getGuaranteeCell,
		size: 120,
	},
	{
		accessorKey: 'actions',
		header: '',
		size: 20,
		cell: ({ row }) => (
			<div
				className="cursor-pointer h-[52px] flex items-center"
				onClick={() => row.toggleExpanded()}
			>
				<ResponsiveIcon icon={iconMap.outlinedArrowDown} />
				{<RenderExpandedRowContent row={row} />}
			</div>
		),
	},
];

export const transactionColumns: (ColumnDef<
	Transaction,
	string | Record<string, string>
> & {
	style?: ColumnStyleType;
	meta?: ColumnMeta<
		{
			filter?: FilterOptionsType<TypeRangeFilterData | string[]>;
		},
		unknown
	>;
})[] = [
	{
		accessorKey: 'dateTime',
		header: () => <span className="block w-24">Date & Time</span>,
		cell: getDateTimeCell,
		enableSorting: true,
		size: 140,
	},
	{
		accessorKey: 'from',
		header: 'From',
		cell: (props) => <WalletCodeCell {...props.getValue<WalletDataType>()} />,
		enableSorting: true,
		size: 100,
		meta: {
			filter: {
				type: FilterTypeEnum.SingleValue,
				data: ['Wallet 1', 'Wallet 2', 'Wallet 3', 'Wallet 4', 'Wallet 5'],
			},
		},
	},
	{
		accessorKey: 'proxy',
		header: 'Proxy',
		cell: (props) => {
			const value = props.getValue<string>();
			return <span className="truncate w-[3.2rem] block">{value}</span>;
		},
		enableSorting: true,
		size: 100,
		meta: {
			filter: {
				type: FilterTypeEnum.SingleValue,
				data: ['Wallet 1', 'Wallet 2', 'Wallet 3', 'Wallet 4', 'Wallet 5'],
			},
		},
	},
	{
		accessorKey: 'to.code',
		header: 'To',
		cell: (props) => <WalletCodeCell code={props.getValue<string>()} />,
		enableSorting: true,
		size: 80,
		meta: {
			filter: {
				type: FilterTypeEnum.SingleValue,
				data: ['Wallet 1', 'Wallet 2', 'Wallet 3', 'Wallet 4', 'Wallet 5'],
			},
		},
	},
	{
		accessorKey: 'hash',
		header: 'Hash#',
		cell: (props) => {
			const value = props.getValue<string>();
			return <span className="underline">{shortenString(value)}</span>;
		},
		size: 100,
		enableSorting: true,
	},
	{
		accessorKey: 'totalAmount',
		header: () => 'Total Amount',
		cell: getTotalAmountCell,
		enableSorting: true,
		size: 120,
		meta: {
			expandableContent: true,
			filter: {
				type: FilterTypeEnum.range,
				data: {
					dropdownItems: _cryptocurrencies.map<string>((item) =>
						item.cryptocurrency.toString()
					),
					range: [0, 100],
				},
			},
		},
	},
	{
		accessorKey: 'riskLevel',
		header: 'Risk Level',
		cell: getRiskLevelCell,
		enableSorting: true,
		size: 120,
	},
	{
		accessorKey: 'riskFactor',
		header: () => <span className="whitespace-nowrap">Risk Factor</span>,
		cell: (props) => {
			const value = props.getValue<string>();
			return <span>{value}</span>;
		},
		size: 120,
		enableSorting: true,
	},
	{
		accessorKey: 'status',
		header: 'Status',
		cell: StatusCell,
		enableSorting: true,
		size: 220,
	},
	{
		accessorKey: 'moneyBackGuarantee',
		header: () => (
			<span>
				Money Back <br /> Guarantee
			</span>
		),
		cell: getGuaranteeCell,
		size: 130,
		enableSorting: true,
	},
	{
		accessorKey: 'actions',
		header: '',
		enableSorting: false,
		size: 20,
		cell: ({ row }) => (
			<div
				className="cursor-pointer h-[52px] flex items-center"
				onClick={() => row.toggleExpanded()}
			>
				<ResponsiveIcon icon={iconMap.outlinedArrowDown} />
				{<RenderExpandedRowContent row={row} />}
			</div>
		),
	},
];
