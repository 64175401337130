import {
	BarChart,
	Bar,
	XAxis,
	YAxis,
	Tooltip,
	LabelList,
	ResponsiveContainer,
	Cell,
} from 'recharts';
import { Props } from 'recharts/types/component/Label';
import BaseTooltip from './tooltips/BaseTooltip';

const chartColorMap: Record<string, string> = {
	total: '#d3d3d3',
	money_back_guarantee: 'url(#baseChartColor)',
};

const renderTopLabel = (props: Props) => {
	const { x, y = 0 } = props;

	return (
		<text
			x={x}
			y={+y - 20}
			fill="#000"
			textAnchor="start"
			dominantBaseline="middle"
			className="tracking-wide"
		>
			{props.name}
		</text>
	);
};

const renderLeftLabel = (props: Props) => {
	const { x = 0, y = 0, width = 0, value } = props;
	const offset = 10;

	return (
		<text
			x={+x + +width + offset}
			y={+y + 10}
			fill="#000"
			textAnchor="start"
			dominantBaseline="middle"
			className="font-bold text-base"
		>
			{value}
		</text>
	);
};

type HorizontalBarChartProps = {
	data: { name: string; value: number; type: string }[];
};

const HorizontalBarChart: React.FC<HorizontalBarChartProps> = ({ data }) => {
	return (
		<ResponsiveContainer width="100%" height={180}>
			<BarChart data={data} layout="vertical" margin={{ right: 30 }}>
				<defs>
					<linearGradient id="baseChartColor" x1="0" y1="0" x2="1" y2="0">
						<stop offset="0%" stopColor="#1CE7DC" stopOpacity={0.8} />
						<stop offset="75.5%" stopColor="#1CE7AC" stopOpacity={0.8} />
						<stop offset="100%" stopColor="#1CE7A2" stopOpacity={0.8} />
					</linearGradient>
				</defs>
				<XAxis type="number" hide />
				<YAxis type="category" dataKey="String" hide />
				<Tooltip
					cursor={{ fill: 'transparent' }}
					contentStyle={{
						backgroundColor: 'white',
						border: 'none',
						boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
					}}
				/>
				<Tooltip content={<BaseTooltip />} />
				<Bar dataKey="value" barSize={18} radius={[0, 5, 5, 0]}>
					<LabelList dataKey="name" content={renderTopLabel} />
					<LabelList dataKey="value" content={renderLeftLabel} />
					{data.map((entry) => {
						return (
							<Cell
								key={entry.name + entry.value}
								fill={chartColorMap[entry.type]}
							/>
						);
					})}
				</Bar>
			</BarChart>
		</ResponsiveContainer>
	);
};

export default HorizontalBarChart;
