import { CellContext } from '@tanstack/react-table';

import { parseDateTime } from '../../../utils/date';
import { TotalAmountType } from '../../../types/transaction';
import {
	CRYPTOCURRENCY,
	cryptocurrencySignMap,
	CURRENCY,
	currencySignMap,
} from '../../../types/currency';
import { formatNumber } from '../../../utils/number';
import { Badge, BadgeProps } from '../../atoms/Badge';

export const getDateTimeCell: <T>(
	props: CellContext<T, string | Record<string, string>>
) => JSX.Element = (props) => {
	const value = props.getValue<string>();
	const { date, time } = parseDateTime(value) ?? { date: '-', time: '-:-' };
	return (
		<div className="flex flex-col al">
			<span>{date}</span>
			<span className="text-sm text-gray-600">{time}</span>
		</div>
	);
};

export const WalletCodeCell = ({
	code,
	wallet,
}: {
	code: string;
	wallet?: string;
}) => {
	return (
		<div className="flex flex-col al">
			<span className="truncate w-[4rem] block">{code}</span>
			{wallet && <span className="text-sm text-grey-600">{wallet}</span>}
		</div>
	);
};

const TotalAmountCell = ({
	mainCurrency,
	secondaryCurrency,
}: {
	mainCurrency: { value: string; sign: string };
	secondaryCurrency: { value: string; sign: string };
}) => {
	return (
		<div className="flex flex-col">
			<span>
				<span className="font-extrabold pr-1">{mainCurrency.sign}</span>
				{mainCurrency.value}
			</span>
			<span className="text-sm text-grey-600">
				<span className="pr-1 ">{secondaryCurrency.sign}</span>
				{secondaryCurrency.value}
			</span>
		</div>
	);
};

export const getTotalAmountCell: <T>(
	props: CellContext<T, string | Record<string, string>>
) => JSX.Element = (props) => {
	const { from, to } = props.cell.getValue<TotalAmountType>();

	const mainCurrency = {
		value: formatNumber(+from.value),
		sign: cryptocurrencySignMap[from.unit as CRYPTOCURRENCY],
	};
	const secondaryCurrency = {
		value: formatNumber(+to.value),
		sign: currencySignMap[to.unit as CURRENCY],
	};

	return (
		<TotalAmountCell
			mainCurrency={mainCurrency}
			secondaryCurrency={secondaryCurrency}
		/>
	);
};

export const getRiskLevelCell: <T>(
	props: CellContext<T, string | Record<string, string>>
) => JSX.Element = (props) => {
	const value = props.getValue<string>();
	return (
		<Badge
			variant={value as BadgeProps['variant']}
			className="capitalize w-[81px] justify-center"
		>
			{value}
		</Badge>
	);
};
