import { all, fork } from 'redux-saga/effects';

import transactionWatcherSaga from '../controllers/dashboard/saga';
import authWatcherSaga from '../controllers/auth/saga';
import walletsWatcherSaga from '../controllers/wallets/saga';
import walletWatcherSaga from '../controllers/wallet/saga';

export default function* rootSaga() {
	yield all([
		fork(transactionWatcherSaga),
		fork(authWatcherSaga),
		fork(walletsWatcherSaga),
		fork(walletWatcherSaga),
	]);
}
