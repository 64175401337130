import { cn } from '../../libs/cn';
import { iconMap } from '../../theme/Icons';
import { Badge } from '../atoms/Badge';
import { Button } from '../atoms/Button';
import ResponsiveIcon from '../atoms/Icon';
import BaseCard from '../organisms/BaseCard';

const MonitoredWalletsPanel: React.FC<{ data: any[] }> = ({ data }) => {
	return (
		<BaseCard
			title={
				<>
					Monitored Wallets <span className="font-normal">5</span>{' '}
				</>
			}
			cardContentStyle="h-[240px] overflow-hidden overflow-y-scroll styled-scroll"
			Footer={
				<Button onClick={() => {}} variant={'outline'} size={'sm'}>
					Manage Wallets
				</Button>
			}
		>
			<div className="flex flex-col justify-between gap-4">
				{data?.map((wallet, index) => (
					<div
						key={index}
						className={cn('grid grid-cols-3 gap-4 p-3 rounded-lg items-center')}
						style={{
							border: `1px solid var(--${wallet?.status?.toLowerCase()})`,
						}}
					>
						<div className="flex items-center gap-2">
							<ResponsiveIcon
								icon={iconMap.wallets}
								className="text-grey-600"
							/>
							<p className="text-base font-bold">{wallet.name}</p>
						</div>

						<p className="truncate w-[5.5rem] block">{wallet?.address}</p>

						<div className="text-right">
							<Badge
								className="items-center"
								variant={wallet?.status?.toLowerCase() as any}
							>
								{wallet?.status}
							</Badge>
						</div>
					</div>
				))}
			</div>
		</BaseCard>
	);
};

export default MonitoredWalletsPanel;
