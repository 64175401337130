import { capitalizeFirstLetter } from '../../../../utils/string';

type BaseTooltipProps = {
	active?: boolean;
	payload?: any[];
};

const BaseTooltip: React.FC<BaseTooltipProps> = ({ active, payload }) => {
	if (active && payload && payload.length) {
		return (
			<div className="bg-white border border-grey-400 p-3">
				{payload.map((item, index) => {
					return (
						<div key={index}>
							<p>{capitalizeFirstLetter(item?.payload?.group)}</p>
							<p>
								{capitalizeFirstLetter(item?.payload.name)}: {item.value}
							</p>
						</div>
					);
				})}
			</div>
		);
	}

	return null;
};

export default BaseTooltip;
