import { useEffect, useState } from 'react';
import useDashboardDataService from '../../controllers/dashboard/service';
import CurrenciesWidget from '../specialized/widgets/CurrenciesWidget';
import { dashboardColumns } from '../specialized/TransactionTable/columns';
import { TransactionTable } from '../specialized/TransactionTable/TransactionTable';
import DateFilterPanel, { Range } from '../specialized/DateFilterPanel';
import Volume from '../specialized/charts/Volume';
import TransactionsInRisk from '../specialized/charts/TransactionInRisk';
import MonitoredWalletsPanel from '../specialized/MonitoredWalletsPanel';
import QuickTransaction from '../specialized/QuickTransaction';
import BaseMultiSelect from '../organisms/BaseMultiSelect';
import { Progress } from '../atoms/Progress';

import { _cryptocurrencies } from '../../_mock';

const Dashboard: React.FC = () => {
	const [selectedRange, setSelectedRange] = useState<Range>(Range.today);
	const [selectedCurrency, setSelectedCurrency] = useState<string[]>([]);

	const {
		data,
		error,
		loading,
		loadTransactions,
		loadMonitoredWallets,
		loadTransactionsStats,
		loadVolume,
	} = useDashboardDataService();

	useEffect(() => {
		loadTransactions();
		loadMonitoredWallets();
		loadTransactionsStats();
		loadVolume();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleFilterChange = (range: Range) => {
		setSelectedRange(range);
	};

	if (
		loading.transactionStats ||
		loading.transactions ||
		loading.wallets ||
		loading.volume
	) {
		return (
			<div className="flex justify-center items-center min-h-[600px]">
				<Progress />
			</div>
		);
	}

	return (
		<div className="flex flex-col gap-5 flex-1">
			<div className="flex flex-col gap-8 md:flex-row items-center">
				<CurrenciesWidget data={_cryptocurrencies} />

				<div className="flex justify-center items-center gap-8">
					<p className="font-bold text-nowrap">Add Currency</p>
					<BaseMultiSelect
						selectedValue={selectedCurrency}
						setSelectedValue={setSelectedCurrency}
						triggerClassName="border-input"
						placeholder="USD, BTC..."
						values={_cryptocurrencies.map(
							(item) => item.cryptocurrency as string
						)}
					/>
				</div>
			</div>

			<DateFilterPanel
				selectedRange={selectedRange}
				onFilterChange={handleFilterChange}
			/>

			<div className="grid grid-cols-1 xl:grid-cols-12 gap-5">
				<div className="col-span-1 xl:col-span-8 2xl:col-span-9 space-y-5">
					<div className="grid grid-cols-1 xl:grid-cols-3 2xl:grid-cols-12 gap-5">
						<div className="col-span-1  xl:col-span-1 2xl:col-span-3">
							<Volume data={data.volume} />
						</div>

						<div className="col-span-1 order-3 2xl:order-none xl:col-span-3 2xl:col-span-5">
							<TransactionsInRisk data={data.transactionStats.byRiskLevel} />
						</div>

						<div className="col-span-1 xl:col-span-2 2xl:col-span-4">
							<MonitoredWalletsPanel data={data.wallets} />
						</div>
					</div>

					<div className="flex-1">
						<TransactionTable
							columns={dashboardColumns}
							isLoading={loading.transactions}
							error={error.transactions}
							title="Last Transactions"
							data={data.transactions}
							onClick={() => {}}
							type="simple"
							btnText="Manage All Transaction"
						/>
					</div>
				</div>

				<div className="col-span-1 xl:col-span-4 2xl:col-span-3">
					<QuickTransaction />
				</div>
			</div>
		</div>
	);
};

export default Dashboard;
