import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import BaseMultiSelect from '../../organisms/BaseMultiSelect';
import WalletsGrid from '../../organisms/WalletsGrid';
import WalletsList from '../../organisms/WalletsList';
import { RiskLevelStats } from '../../molecules/RiskLevelStats';
import { Progress } from '../../atoms/Progress';
import AddNewWallet from '../../specialized/dialogs/AddNewWallet';
import { Input } from '../../atoms/Input';

import useWalletsService from '../../../controllers/wallets/service';
import {
	DisplayMode,
	DisplayModeSwitcher,
} from '../../molecules/DisplayModeSwitcher';
import { iconMap } from '../../../theme/Icons';
import { RiskLvl } from '../../../types/transaction';

import { _cryptocurrencies } from '../../../_mock';

export const riskLevelColorMap = {
	[RiskLvl.critical]: 'bg-critical-100',
	[RiskLvl.medium]: 'bg-medium-100',
	[RiskLvl.high]: 'bg-high-100',
	[RiskLvl.low]: 'bg-low-100',
};

const WalletsPage: React.FC = () => {
	const navigate = useNavigate();

	const { data, getWallets, loading } = useWalletsService();

	const [selectedCurrency, setSelectedCurrency] = useState<string[]>([]);
	const [selectedMode, setSelectedMode] = useState<DisplayMode>(
		DisplayMode.grid
	);

	useEffect(() => {
		getWallets();
	}, [getWallets]);

	const handleChangeDisplayMode = (mode: DisplayMode) => {
		setSelectedMode(mode);
	};

	const goToWalletDetails = (id: number) => {
		navigate(`/wallets/${id}`);
	};

	if (loading.wallets) {
		return (
			<div className="flex justify-center items-center min-h-[600px]">
				<Progress />
			</div>
		);
	}

	return (
		<>
			<div className="flex flex-col gap-8">
				<div className="flex gap-4 2xl:gap-8 items-center flex-col 2xl:flex-row justify-between">
					<div className="flex flex-col lg:flex-row gap-4 lg:gap-8 2xl:gap-14 items-center xl:self-start">
						<RiskLevelStats data={data.wallets} />

						<div className="flex gap-4 lg:gap-8 2xl::gap-14 flex-wrap lg:flex-nowrap lg:flex-row justify-center">
							<div className="flex gap-3 items-center">
								<span className="font-bold">Currency</span>
								<BaseMultiSelect
									selectedValue={selectedCurrency}
									triggerClassName="border-black"
									placeholder="USD, BTC..."
									setSelectedValue={setSelectedCurrency}
									values={_cryptocurrencies.map(
										(item) => item.cryptocurrency as string
									)}
								/>
							</div>

							<Input
								icon={iconMap.search}
								placeholder="Search"
								className="bg-grey-50 border-none"
							/>
						</div>
					</div>

					<DisplayModeSwitcher
						modes={[DisplayMode.grid, DisplayMode.list]}
						selectedMode={selectedMode}
						onChangeDisplayMode={handleChangeDisplayMode}
						icons={{
							grid: iconMap.tileMode,
							list: iconMap.listMode,
						}}
						title={'View'}
					/>
				</div>

				<div>
					{selectedMode === DisplayMode.list ? (
						<WalletsList
							data={data.wallets}
							goToWalletDetails={goToWalletDetails}
						/>
					) : (
						<WalletsGrid
							data={data.wallets}
							goToWalletDetails={goToWalletDetails}
						/>
					)}
				</div>
			</div>

			<div className="pt-6 pl-6 rounded-full fixed bottom-4 right-4 gap-3 flex flex-col">
				<AddNewWallet />
				<p>Add Wallet</p>
			</div>
		</>
	);
};

export default WalletsPage;
