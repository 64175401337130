import {
	_monitoredWallets,
	_transactions,
	_transactionsInRisk,
	_transactionsStatus,
	_volume,
} from '../../_mock';

export const getTransactionsApi = () => {
	return new Promise((resolve) => {
		const mockData = _transactions;

		setTimeout(() => {
			resolve({ data: mockData });
		}, 1000);
	});
};

export const getTransactionsStatsApi = () => {
	return new Promise((resolve) => {
		const mockData = {
			byStatus: _transactionsStatus,
			byRiskLevel: _transactionsInRisk,
		};

		setTimeout(() => {
			resolve({ data: mockData });
		}, 2000);
	});
};

export const getMonitoredWalletsApi = () => {
	return new Promise((resolve) => {
		const mockData = _monitoredWallets;

		setTimeout(() => {
			resolve({ data: mockData });
		}, 2000);
	});
};

export const getVolumesApi = () => {
	return new Promise((resolve) => {
		const mockData = _volume;

		setTimeout(() => {
			resolve({ data: mockData });
		}, 2000);
	});
};
