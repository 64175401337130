import { useCallback } from 'react';

import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { AuthState, authActions } from './slice';

type AuthServiceOperators = AuthState & {
	login: (email: string, password: string) => void;
	verifyLogin: (code: string[]) => void;
	logout: () => void;
	clearError: () => void;
};

const useAuthService = (): Readonly<AuthServiceOperators> => {
	const dispatch = useAppDispatch();
	const authState = useAppSelector((state) => state.auth);

	return {
		data: authState.data,
		loading: authState.loading,
		error: authState.error,
		login: useCallback(
			(email, password) => {
				dispatch(authActions.loginRequest({ email, password }));
			},
			[dispatch]
		),
		verifyLogin: useCallback(
			(code) => {
				dispatch(authActions.verificationRequest(code));
			},
			[dispatch]
		),
		logout: useCallback(() => {
			dispatch(authActions.logout());
		}, [dispatch]),
		clearError: useCallback(() => {
			dispatch(authActions.clearError());
		}, [dispatch]),
	};
};

export default useAuthService;
