import React, { useEffect, useRef, useState } from 'react';
import { cn } from '../../libs/cn';
import { iconMap } from '../../theme/Icons';
import { Button } from '../atoms/Button';
import ResponsiveIcon from '../atoms/Icon';
import { Input } from '../atoms/Input';
import {
	MultiSelector,
	MultiSelectorContent,
	MultiSelectorItem,
	MultiSelectorList,
	MultiSelectorTrigger,
} from '../atoms/MultiSelect';
import { Badge } from '../atoms/Badge';
import BaseCard from '../organisms/BaseCard';

import { _cryptocurrencies } from '../../_mock';
import BackGuaranteeWidget from './widgets/BackGuaranteeWidget';
import AddRecipientDialog from './dialogs/AddRecipientDialog';
import RisksPopover from './popovers/RisksPopover';
import ProgressBar from '../atoms/ProgressBar';
import { RiskLvl } from '../../types/transaction';

type QuickTransactionType = {
	onSend?: () => void;
} & Pick<React.HTMLAttributes<HTMLDivElement>, 'className'>;

const quickTransactionWidgetBgImageClass =
	'bg-[url("https://s3-alpha-sig.figma.com/img/a28b/0eb3/cbe640868d6d2a981c03acca6a32292f?Expires=1720396800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=eDj-416TXRPqYVFc~BLTsg~jXEA2MCM23vY7zYK4CRv6raAb2obX8T3RLLt1limVsqQW9q72b1yhcy1OTVzypkq75mMHZSiQc2G6p0~HumRftjFBRPnj-7Jg0lr6ezgdE3ZGVxstrPzJdc2z1gemXIm0-Uu3fa0fOFZV9H3KAjW2~l0lMxoCPvT9DmacONGGW1kd8d5m6pO15ZmB4wB84WZ-01hDpug0dEK9lZO1-zhNg9dFWrOF0HvayzCbVtW~LN67zdcsiOolMYgVzizNIoON70xr1Ru6p6s0ZUWp-7gvRuY6hit5dOUggSA1aWpor79SiKCWwh5Nzp78pHBCpQ__")]';

const CurrencyCheckBox = ({ width }: { width?: number }) => {
	const currency = _cryptocurrencies.map((v) => v?.cryptocurrency as string);

	const [value, setValue] = useState([currency[0]]);

	const handleValueChange = (value: [string]) => {
		const lastItem = value.pop();
		setValue(lastItem ? [lastItem] : []);
	};

	return (
		<MultiSelector
			values={value}
			onValuesChange={handleValueChange}
			loop
			className="text-white w-[5.7rem] relative"
		>
			<div className="absolute h-[28px] top-2 bg-gray-300 border-l"></div>
			<MultiSelectorTrigger className="border rounded-tl-none rounded-bl-none border-l-0 border-white" />
			<MultiSelectorContent>
				<MultiSelectorList
					className="bg-black w-[5.5rem] p-0 absolute top-0 right-0"
					style={{ width: width && width > 0 ? width : 'initial' }}
				>
					{currency.map((item, index) => (
						<MultiSelectorItem
							key={index}
							value={item}
							color="white"
							className="gap-2"
							checkBoxClassName="border-white"
						>
							{item}
						</MultiSelectorItem>
					))}
				</MultiSelectorList>
			</MultiSelectorContent>
		</MultiSelector>
	);
};

const CurrencyConvertor = () => {
	return (
		<MultiSelector
			values={['USD']}
			onValuesChange={() => {}}
			loop
			className="text-white w-[5.7rem] relative"
		>
			<div className="absolute h-[28px] top-2 bg-gray-300 border-l"></div>
			<MultiSelectorTrigger
				hideArrow
				className="border rounded-tl-none rounded-bl-none border-l-0 border-white mt-0"
			/>
		</MultiSelector>
	);
};

interface SelectInputProps {
	value: string;
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	width?: number;
}

const SelectInput = React.forwardRef<HTMLDivElement, SelectInputProps>(
	({ value, onChange, width }, ref) => {
		return (
			<div ref={ref} className="flex items-center sm:w-1/2">
				<Input
					className="border-white text-white rounded-br-none rounded-tr-none border-r-0"
					rootClassName="w-full"
					value={value}
					onChange={onChange}
					type="number"
				/>
				<CurrencyCheckBox width={width} />
			</div>
		);
	}
);
SelectInput.displayName = 'SelectInput';

interface CurrencyInputProps {
	value: number | undefined;
}

const CurrencyInput: React.FC<CurrencyInputProps> = ({ value }) => {
	return (
		<div className="flex items-center sm:w-1/2">
			<Input
				className="border-white text-white rounded-br-none rounded-tr-none border-r-0 cursor-not-allowed"
				rootClassName="w-full"
				value={value ? value : ''}
			/>
			<CurrencyConvertor />
		</div>
	);
};

interface FeesBannerProps {
	hideMoneyBack: boolean;
}

const FeesBanner: React.FC<FeesBannerProps> = ({ hideMoneyBack }) => (
	<div className="flex gap-4 sm:gap-8 text-white flex-wrap">
		<div className="flex flex-col gap-6">
			<p className="flex gap-1 items-center">
				<ResponsiveIcon icon={iconMap.hourglass} />
				<span>Transaction Fees</span>
			</p>
			{hideMoneyBack && (
				<p className="flex gap-1 items-center">
					<ResponsiveIcon icon={iconMap.hourglass} />
					<span className="leading-5">
						Money Back <br /> Guarantee Fees{' '}
					</span>
				</p>
			)}
		</div>
		<div className="flex flex-col gap-6">
			<div className="flex gap-3">
				<p className="flex gap-1 items-center">
					<ResponsiveIcon icon={iconMap.bitcoin} />
					<span>0.05</span>
				</p>
				<p className="flex gap-1 items-center">
					<ResponsiveIcon icon={iconMap.dollar} />
					<span>3265.05</span>
				</p>
			</div>
			{hideMoneyBack && (
				<div className="flex gap-3">
					<p className="flex gap-1 items-center">
						<ResponsiveIcon icon={iconMap.bitcoin} />
						<span>0.05</span>
					</p>
					<p className="flex gap-1 items-center">
						<ResponsiveIcon icon={iconMap.dollar} />
						<span>3265.05</span>
					</p>
				</div>
			)}
		</div>
	</div>
);

const InformationBanner = ({
	value,
	onClick,
}: {
	value: RiskLvl;
	onClick: () => void;
}) => (
	<div className="flex gap-5 text-white mb-8">
		<Badge variant={value} onClick={onClick}>
			{value}
		</Badge>
		<RisksPopover />
	</div>
);

const QuickTransaction: React.FC<QuickTransactionType> = () => {
	const [recipient, setRecipient] = useState('');
	const [currencyAmount, setCurrencyAmount] = useState('');
	const [convertedAmount, setConvertedAmount] = useState<number>(0);
	const [checked, setChecked] = useState(false);
	const [status, setStatus] = useState(RiskLvl.high);
	const [width, setWidth] = useState(0);

	const selectRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (selectRef?.current) {
			setWidth(selectRef.current.clientWidth);

			const getwidth = () => {
				if (selectRef?.current) {
					setWidth(selectRef.current.clientWidth);
				}
			};
			window.addEventListener('resize', getwidth);
			return () => window.removeEventListener('resize', getwidth);
		}
	}, []);

	const handleRiskStatus = () => {
		// mock function to set risk status to 'critical'
		setStatus(RiskLvl.critical);
	};

	const handleChecked = () => {
		setChecked((prev) => !prev);
	};

	const [isError, setIsError] = useState(false);
	const [processingTime] = useState(5);

	const toggleErrorMessage = () => {
		setIsError((prev) => !prev);
	};

	const handleCurrencyAmountChange = (
		e: React.ChangeEvent<HTMLInputElement>
	) => {
		setCurrencyAmount(e.target.value);
		const convertedValue = Number(e.target.value) * 5;

		if (convertedValue && !isNaN(convertedValue) && convertedValue > 0) {
			setConvertedAmount(convertedValue);
		} else {
			setConvertedAmount(0);
		}
	};

	const isCritical = status === RiskLvl.critical;
	const hideInfoPanel = isCritical || !recipient;

	return (
		<BaseCard
			title="Quick Transaction"
			cardContentStyle="h-full"
			cardHeaderStyle="text-primary-300 mb-4"
			cardStyle={cn(
				'bg-grey-800 bg-cover bg-quick-transaction text-white',
				quickTransactionWidgetBgImageClass
			)}
			Footer={
				<div className="flex flex-col gap-10 w-full">
					{!hideInfoPanel && (
						<>
							<TransactionTime
								time={processingTime}
								current={40}
								isError={isError}
								onClick={toggleErrorMessage}
							/>

							<FeesBanner hideMoneyBack={checked} />
						</>
					)}

					<div className="flex items-center gap-6 mb-4">
						{!hideInfoPanel && (
							<p className="text-white">
								System will automaticly connect to your wallet
							</p>
						)}
						<Button
							icon={
								<ResponsiveIcon
									icon={isCritical ? iconMap.crossOutlined : iconMap.arrow}
									className="w-5 h-5"
								/>
							}
							iconPosition="right"
							variant={isCritical || recipient ? 'default' : 'outline'}
							disabled={!recipient || isCritical}
							className={`text-lg mr-0 ml-auto bg-gradient-primary-rtl ${isCritical ? 'border-none' : ''}`}
						>
							Send
						</Button>
					</div>
				</div>
			}
		>
			<p className="font-bold">To</p>
			<div className="flex gap-3 mb-4">
				<Input
					className="border-white select-none "
					rootClassName="w-full"
					value={recipient}
				/>
				<AddRecipientDialog setRecipient={setRecipient} />
			</div>
			{recipient && (
				<InformationBanner value={status} onClick={handleRiskStatus} />
			)}

			<div className="flex flex-col gap-3 mb-12 relative">
				<p className="text-white font-bold">Amount</p>
				<div className="flex gap-4 justify-between flex-col sm:flex-row">
					<SelectInput
						value={currencyAmount}
						onChange={handleCurrencyAmountChange}
						ref={selectRef}
						width={width}
					/>
					<CurrencyInput value={convertedAmount} />
				</div>
			</div>

			<BackGuaranteeWidget checked={checked} onChange={handleChecked} />
		</BaseCard>
	);
};

export default QuickTransaction;

interface TransactionTimeProps {
	time: number;
	current: number;
	onClick: () => void;
	isError: boolean;
}

const TransactionTime: React.FC<TransactionTimeProps> = ({
	time,
	current,
	isError,
	onClick,
}) => {
	const rocessingMessage = 'Time To Complete Transaction';
	const errorMessage = 'Transaction Time Expired. Please Try Again.';

	return (
		<div
			className="flex flex-col w-full text-white text-[15px]"
			onClick={onClick}
		>
			{isError ? (
				<p className="bg-gradient-high bg-clip-text text-transparent-fill">
					{errorMessage}
				</p>
			) : (
				<p>{rocessingMessage}</p>
			)}

			<ProgressBar progress={current} type="line" isError={isError} />

			<div className="flex justify-between items-center text-xs mt-1">
				<span>0 min</span>
				<span>{time} min</span>
			</div>
		</div>
	);
};
