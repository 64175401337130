import { _transactions, _wallets } from '../../_mock';

export const getWalletByIdApi = (id: number) => {
	return new Promise((resolve) => {
		const mockData = _wallets.find((wallet) => wallet.id === id);

		setTimeout(() => {
			resolve({ data: mockData });
		}, 1000);
	});
};

export const getTransactionsByWalletIdApi = (id: number) => {
	// mock data filtered from _transactions
	return new Promise((resolve) => {
		const outTransactions = _transactions
			.filter((t) => t.from.walletId === id)
			?.map((el) => ({ ...el, type: 'out' }));

		const inTransactions = _transactions
			.filter((t) => t.to.walletId === id)
			?.map((el) => ({ ...el, type: 'in' }));

		setTimeout(() => {
			resolve({ data: [...outTransactions, ...inTransactions] });
		}, 1000);
	});
};
