import { useCallback } from 'react';

import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { WalletState, walletActions } from './slice';

type WalletServiceOperators = WalletState & {
	getWalletById: (id: number) => void;
	getTransactionsByWalletId: (id: number) => void;
};

const useWalletService = (): Readonly<WalletServiceOperators> => {
	const dispatch = useAppDispatch();
	const walletsState = useAppSelector((state) => state.wallet);

	return {
		data: walletsState.data,
		loading: walletsState.loading,
		error: walletsState.error,
		getWalletById: useCallback(
			(id: number) => {
				dispatch(walletActions.fetchWalletByIdRequest(id));
			},
			[dispatch]
		),
		getTransactionsByWalletId: useCallback(
			(id: number) => {
				dispatch(walletActions.fetchTransactionsByWalletIdRequest(id));
			},
			[dispatch]
		),
	};
};

export default useWalletService;
