import { Navigate } from 'react-router-dom';

import useAuthService from '../controllers/auth/service';

const RequireAuth = ({ children }: any) => {
	const {
		data: { isAuthenticated },
	} = useAuthService();

	return isAuthenticated ? children : <Navigate to="/auth/login" replace />;
};

export default RequireAuth;
