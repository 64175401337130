import { Card, CardContent } from '../../atoms/Card';

type CurrenciesWidgetProps = {
	data: {
		cryptocurrency: string | number;
		amount: {
			value: number;
			unit: string | number;
		};
	}[];
};

const CurrenciesWidget: React.FC<CurrenciesWidgetProps> = ({ data }) => {
	return (
		<Card className="overflow-x-auto styled-scroll p-2 sm:p-2 flex items-center w-full">
			<CardContent className="flex divide-x pl-3">
				{data.map((item, index) => (
					<div
						key={index}
						className="flex gap-5 justify-center items-center px-5"
					>
						<p>{item?.cryptocurrency}</p>
						<p>${item?.amount?.value}</p>
					</div>
				))}
			</CardContent>
		</Card>
	);
};

export default CurrenciesWidget;
