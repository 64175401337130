import { Card } from '../atoms/Card';
import { Badge } from '../atoms/Badge';
import { Button } from '../atoms/Button';
import ResponsiveIcon from '../atoms/Icon';
import { iconMap } from '../../theme/Icons';
import { Wallet } from '../../types/wallets';

import { cn } from '../../libs/cn';

interface WalletListItemProps {
	item: Wallet;
	bgColor?: string;
	goToWalletDetails: (id: number) => void;
}

const WalletListItem: React.FC<WalletListItemProps> = ({
	item,
	bgColor,
	goToWalletDetails,
}) => {
	return (
		<Card
			key={item.id}
			className="sm:py-2 flex flex-col lg:flex-row items-center gap-4 justify-between hover:shadow-hover-lg hover:cursor-pointer transition-all"
			onClick={() => goToWalletDetails(item.id)}
		>
			<div className="flex-1 max-w-16 xl:max-w-20">
				<p>{item.name}</p>
			</div>
			<div className="flex-1 max-w-[120px] xl:max-w-[170px]">
				<p className="text-gray-600 truncate">{item.address}</p>
			</div>
			<div className="flex-1 flex items-center justify-start">
				<div className="flex gap-2 flex-wrap">
					{item?.cryptocurrencies.map((item) => {
						return (
							<div key={item?.code} className="bg-grey-300 p-2 rounded-full">
								<ResponsiveIcon
									icon={iconMap[item.code.toLowerCase()]}
									className="w-5 h-5"
								/>
							</div>
						);
					})}
				</div>
			</div>

			<div className="flex-1 flex max-w-28">
				<Badge variant={item?.riskLevel}>{item?.riskLevel}</Badge>
			</div>
			<div className="flex-1 flex items-center justify-start">
				<ul className="flex gap-2 flex-wrap">
					{item?.riskFactors.map((factor) => (
						<Badge
							key={factor}
							className={cn('text-black px-2 py-0 min-w-20', bgColor)}
						>
							{factor}
						</Badge>
					))}
				</ul>
			</div>

			<div className="flex-1 flex gap-1 xl:gap-6 items-center justify-between w-full lg:max-w-56">
				<Button
					disabled={!item.canSend}
					iconPosition="right"
					icon={<ResponsiveIcon icon={iconMap.arrow} className="w-4 h-5" />}
				>
					Go to Send
				</Button>
				<Button
					size={'icon'}
					variant={'icon'}
					icon={<ResponsiveIcon icon={iconMap.delete} className="w-4 h-5" />}
					className="bg-transparent border-none"
				/>
			</div>
		</Card>
	);
};

export default WalletListItem;
