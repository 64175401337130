export function reorderProperties<T extends object>(
	obj: T,
	order: (keyof T)[]
): T | undefined {
	if (typeof obj !== 'object' || obj === null || Array.isArray(obj)) {
		return;
	}

	if (!Array.isArray(order)) {
		return;
	}

	const orderedObj = {} as T;

	order.forEach((key: keyof T) => {
		if (key in obj) {
			orderedObj[key] = obj[key];
		} else {
			return;
		}
	});

	(Object.keys(obj) as (keyof T)[]).forEach((key: keyof T) => {
		if (!(key in orderedObj)) {
			orderedObj[key] = obj[key];
		}
	});

	return orderedObj;
}
