import { riskLevelColorMap } from '../pages/wallets/WalletsPage';
import WalletCard from '../molecules/WalletCard';
import { Wallet } from '../../types/wallets';

interface WalletsGridProps {
	data: Wallet[];
	goToWalletDetails: (id: number) => void;
}

const WalletsGrid: React.FC<WalletsGridProps> = ({
	data,
	goToWalletDetails,
}) => {
	return (
		<div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4">
			{data?.map((wallet) => {
				const factorBg = riskLevelColorMap[wallet?.riskLevel] || 'bg-gray-100';
				return (
					<WalletCard
						key={wallet?.id}
						item={wallet}
						bgColor={factorBg}
						goToWalletDetails={goToWalletDetails}
					/>
				);
			})}
		</div>
	);
};

export default WalletsGrid;
