import * as React from 'react';
import { cva, type VariantProps } from 'class-variance-authority';
import { cn } from '../../libs/cn';
import ProgressBar from './ProgressBar';
import { TransactionStatus } from '../../types/transaction';

const statusVariants = cva(
	'inline-flex items-center rounded-full border h-3 w-3',
	{
		variants: {
			variant: {
				default:
					'border-transparent bg-gradient-primary hover:bg-gradient-primary/80',
				transferring:
					'border-transparent bg-gradient-primary hover:bg-gradient-primary/80',
				rejected:
					'border-transparent bg-gradient-critical text-white hover:bg-gradient-critical/80',
				expired:
					'border-transparent bg-grey-300 text-white hover:bg-gradient-critical/80',
				completed:
					'border-transparent bg-gradient-primary hover:bg-gradient-medium/80',
			},
		},
		defaultVariants: {
			variant: 'default',
		},
	}
);

export interface StatusProps
	extends React.HTMLAttributes<HTMLDivElement>,
		VariantProps<typeof statusVariants> {}

function Status({ className, variant, ...props }: StatusProps) {
	return variant === TransactionStatus.transferring ? (
		<ProgressBar progress={60} className="max-w-20" />
	) : (
		<div className={cn(statusVariants({ variant }), className)} {...props} />
	);
}

export { Status, statusVariants };
