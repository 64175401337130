import { iconMap } from '../../../theme/Icons';
import { Transaction } from '../../../types/transaction';
import { Button } from '../../atoms/Button';
import ResponsiveIcon from '../../atoms/Icon';
import { BaseTable } from '../../organisms/Table/BaseTable';

interface TransactionHistoryTableProps {
	data: Transaction[];
	columns: any;
	isLoading: boolean;
	error: string | null;
}

const TransactionHistoryTable: React.FC<TransactionHistoryTableProps> = ({
	data,
	columns,
	isLoading,
	error,
}) => {
	return (
		<div className="flex flex-col gap-3">
			<div className="flex justify-between items-center">
				<p className="text-lg font-bold px-3">Transaction History</p>
				<Button
					onClick={() => {}}
					iconPosition="left"
					icon={<ResponsiveIcon icon={iconMap.report} className="h-5 w-5" />}
					className="bg-transparent border-none"
				>
					Report
				</Button>
			</div>
			<BaseTable
				columns={columns}
				data={data}
				isLoading={isLoading}
				error={error}
			/>
		</div>
	);
};

export default TransactionHistoryTable;
