type PageWrapperProps = {
	children: JSX.Element | JSX.Element[];
};

const PageContainer: React.FC<PageWrapperProps> = ({ children }) => {
	return (
		<div className="flex flex-col gap-4 md:gap-6 lg:gap-9 h-full px-3 sm:px-6 pb-6">
			{children}
		</div>
	);
};

export default PageContainer;
