import useAuthService from '../../../controllers/auth/service';
import GuaranteePanel from '../../specialized/GuaranteePanel';
import { CustomRouteObject } from '../../../routes/router';
import { SidebarItem } from './SidebarItem';
import ResponsiveIcon from '../../atoms/Icon';
import { Button } from '../../atoms/Button';
import Logo from '../../atoms/Logo';
import { IconType } from '../../../types/common';
import { iconMap } from '../../../theme/Icons';

type SidebarProps = {
	routes: CustomRouteObject[];
};

const MenuItems = ({ routes }: { routes: CustomRouteObject[] }) => {
	return (
		<div>
			{routes.map((route) => {
				return (
					<SidebarItem
						key={route.path}
						to={route.path}
						icon={route.icon as IconType}
						label={route?.handle?.()?.label ?? ''}
					/>
				);
			})}
		</div>
	);
};

const LogoutMenuItem = () => {
	const { logout } = useAuthService();

	return (
		<SidebarItem icon={iconMap.logout} label={'Log out'} onClick={logout} />
	);
};

const Sidebar: React.FC<SidebarProps> = ({ routes }) => {
	const topMenuItems = routes?.filter((r) => r.handle?.().position === 'top');
	const bottomMenuItems = routes?.filter(
		(r) => r.handle?.().position === 'bottom'
	);

	return (
		<div className="h-full flex flex-col shadow-sm inset-y-0 z-50 bg-black pb-6 xl:10">
			<div className="pl-7 pt-6 pb-5 flex justify-start px-4 border">
				<Logo className="w-[88px] h-[52px]" />
			</div>

			<div className="flex flex-col w-full styled-scroll overflow-y-auto gap-8 px-4 justify-between h-full">
				<div className="flex flex-col gap-4">
					<MenuItems routes={topMenuItems} />
					<Button className="mx-auto">
						Send <ResponsiveIcon icon={iconMap.arrow} />
					</Button>
				</div>

				<div className="flex flex-col gap-4">
					<GuaranteePanel />
					<div>
						<MenuItems routes={bottomMenuItems} />
						<LogoutMenuItem />
					</div>
				</div>
			</div>
		</div>
	);
};

export default Sidebar;
