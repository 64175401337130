interface LoginProps {
	email: string;
	password: string;
}

const _EMAIL = 'admin@gmail.com';
const _PASSWORD = 'Qwerty123!';
const _ACCESS_CODE = '123123';

export const loginApi = ({ email, password }: LoginProps) => {
	return new Promise((resolve, reject) => {
		setTimeout(() => {
			if (email === _EMAIL && password === _PASSWORD) {
				resolve({ data: { email, password } });
			} else {
				reject('Failed to log in');
			}
		}, 600);
	});
};

export const verifyLoginApi = (code: string[]) => {
	const mockAccess = code.join('');

	return new Promise((resolve, reject) => {
		setTimeout(() => {
			if (mockAccess === _ACCESS_CODE) {
				resolve({ data: mockAccess });
			} else {
				reject('Failed to verify');
			}
		}, 600);
	});
};
