import * as React from 'react';
import * as CheckboxPrimitive from '@radix-ui/react-checkbox';

import { cn } from '../../libs/cn';
import ResponsiveIcon from './Icon';
import { iconMap } from '../../theme/Icons';

const Checkbox = React.forwardRef<
	React.ElementRef<typeof CheckboxPrimitive.Root>,
	React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root> & {
		iconClassNames?: string;
	}
>(({ className, color, iconClassNames, ...props }, ref) => (
	<CheckboxPrimitive.Root
		ref={ref}
		className={cn(
			'peer h-[17px] w-[17px] shrink-0 rounded-xs border border-input disabled:border-grey-600 disabled:cursor-not-allowed disabled:bg-grey-300 data-[state=checked]:text-primary-foreground',
			className
		)}
		{...props}
	>
		<CheckboxPrimitive.Indicator
			className={cn('flex items-center justify-center')}
		>
			<ResponsiveIcon
				icon={iconMap.checked}
				size={12}
				color={color}
				className={cn('text-grey-900', iconClassNames)}
			/>
		</CheckboxPrimitive.Indicator>
	</CheckboxPrimitive.Root>
));
Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export { Checkbox };
