import {
	MultiSelector,
	MultiSelectorContent,
	MultiSelectorItem,
	MultiSelectorList,
	MultiSelectorTrigger,
} from '../atoms/MultiSelect';

import { cn } from '../../libs/cn';
import { capitalizeFirstLetter } from '../../utils/string';

type CryptocurrencyProps = {
	selectedValue: string[];
	setSelectedValue: (v: string[]) => void;
	values: string[];
	className?: string;
	triggerClassName?: string;
	placeholder?: string;
};

const BaseMultiSelect: React.FC<CryptocurrencyProps> = ({
	selectedValue,
	setSelectedValue,
	values,
	className,
	triggerClassName,
	placeholder,
	...props
}) => {
	return (
		<MultiSelector
			values={selectedValue}
			onValuesChange={setSelectedValue}
			className={cn('space-y-0', className)}
			loop
			{...props}
		>
			<MultiSelectorTrigger
				className={triggerClassName}
				placeholder={placeholder}
			/>
			<MultiSelectorContent>
				<MultiSelectorList>
					{values.map((item, index) => (
						<MultiSelectorItem key={index} value={item as string}>
							{capitalizeFirstLetter(item)}
						</MultiSelectorItem>
					))}
				</MultiSelectorList>
			</MultiSelectorContent>
		</MultiSelector>
	);
};

export default BaseMultiSelect;
