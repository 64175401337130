import { useEffect } from 'react';
import { z } from 'zod';
import { useNavigate } from 'react-router-dom';

import LoginForm, { formSchema } from '../../organisms/auth/Login/LoginForm';
import useAuthService from '../../../controllers/auth/service';

const LoginPage: React.FC = () => {
	const navigate = useNavigate();
	const {
		login,
		data: { isAuthenticated },
	} = useAuthService();

	const onSubmit = async (values: z.infer<typeof formSchema>) => {
		try {
			login(values.email, values.password);
		} catch (error) {
			console.log('error', error);
		}
	};

	useEffect(() => {
		if (isAuthenticated) {
			navigate('/auth/verify-code');
		}
	}, [isAuthenticated, navigate]);

	return (
		<>
			<div className="flex flex-col gap-3">
				<h2 className="text-5xl font-bold">Log In</h2>
				<div>
					<p className="leading-5">Get a verification code to access your</p>
					<p>SailoTech account</p>
				</div>
			</div>

			<LoginForm onSignInSuccess={onSubmit} />
		</>
	);
};

export default LoginPage;
