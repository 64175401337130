import { useState } from 'react';
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';

import { iconMap } from '../../../theme/Icons';
import { Button } from '../../atoms/Button';
import {
	Dialog,
	DialogContent,
	DialogFooter,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from '../../atoms/Dialog';
import ResponsiveIcon from '../../atoms/Icon';
import { Input } from '../../atoms/Input';
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from '../../atoms/Form';

// Custom validation schema
const formSchema = z.object({
	address: z.string(),
	name: z.string(),
});

type AddRecipientDialogProps = {
	setRecipient: (recipient: string) => void;
};

const AddRecipientDialog: React.FC<AddRecipientDialogProps> = ({
	setRecipient,
}) => {
	const [isOpen, setIsOpen] = useState(false);

	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		mode: 'onChange',
		defaultValues: {
			address: '',
			name: '',
		},
	});

	// Form states and methods
	// const { isSubmitting, isValid } = form.formState;

	const onSubmit = async (values: z.infer<typeof formSchema>) => {
		setRecipient(values.address);
		setIsOpen(false);
	};

	return (
		<Dialog open={isOpen} onOpenChange={setIsOpen}>
			<DialogTrigger asChild>
				<div>
					<Button
						size="icon"
						variant={'icon'}
						className="border-primary bg-transparent"
					>
						<ResponsiveIcon
							icon={iconMap.plus}
							color="#1CE7AC"
							className="w-4 h-4"
						/>
					</Button>
				</div>
			</DialogTrigger>

			<DialogContent className="sm:max-w-[600px] p-11 gap-8">
				<DialogHeader>
					<DialogTitle className="flex flex-col sm:flex-row gap-2 sm:gap-5 items-center text-2xl font-bold text-center border-b border-[#A7D0C7] pb-3">
						<ResponsiveIcon
							icon={iconMap.addRecipient}
							color={'var(--color-primary-300)'}
						/>
						<p>Add New Recipient</p>
					</DialogTitle>
				</DialogHeader>

				<Form {...form}>
					<form
						onSubmit={form.handleSubmit(onSubmit)}
						className="flex flex-col gap-y-8"
					>
						<FormField
							control={form.control}
							name="address"
							render={({ field }) => (
								<FormItem>
									<FormLabel>Address</FormLabel>
									<FormControl>
										<Input
											{...field}
											autoComplete="off"
											placeholder="bc1d42UNb54eBiGm0qEM0h6r2h8n532to9jtp186ns"
										/>
									</FormControl>
									<FormMessage />
								</FormItem>
							)}
						/>

						<FormField
							control={form.control}
							name="name"
							render={({ field }) => (
								<FormItem>
									<FormLabel>Name</FormLabel>
									<FormControl>
										<Input
											{...field}
											className="max-w-56"
											autoComplete="off"
											placeholder="Kucoin"
										/>
									</FormControl>
									<FormMessage />
								</FormItem>
							)}
						/>

						<DialogFooter>
							<Button type="submit" className="text-lg">
								Save
							</Button>
						</DialogFooter>
					</form>
				</Form>
			</DialogContent>
		</Dialog>
	);
};

export default AddRecipientDialog;
