import { Navigate } from 'react-router-dom';

import useAuthService from '../controllers/auth/service';

const AuthAndVerificationGuard = ({ children }: any) => {
	const {
		data: { isAuthenticated, isVerified },
	} = useAuthService();

	if (!isAuthenticated) {
		return <Navigate to="/auth/login" replace />;
	}

	if (!isVerified) {
		return <Navigate to="/auth/verify-code" replace />;
	}

	return children;
};

export default AuthAndVerificationGuard;
