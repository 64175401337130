import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { Transaction, TransactionStats } from '../../types/transaction';
import { PieChartData } from '../../components/organisms/Chart/PieChart';

export type DashboardState = {
	data: {
		transactions: Transaction[];
		transactionStats: TransactionStats;
		wallets: any[];
		volume: any[]; // TODO: define type when they are defined
	};
	loading: {
		transactions: boolean;
		transactionStats: boolean;
		wallets: boolean;
		volume: boolean;
	};
	error: {
		transactions: string | null;
		transactionStats: string | null;
		wallets: string | null;
		volume: string | null;
	};
};

const initialState: DashboardState = {
	data: {
		transactions: [],
		transactionStats: {
			byRiskLevel: [],
			byStatus: [],
		},
		wallets: [],
		volume: [],
	},
	loading: {
		transactions: false,
		transactionStats: false,
		wallets: false,
		volume: false,
	},
	error: {
		transactions: null,
		transactionStats: null,
		wallets: null,
		volume: null,
	},
};

const dashboardSlice = createSlice({
	name: 'transactions',
	initialState,
	reducers: {
		fetchTransactionsRequest: (state) => {
			state.loading.transactions = true;
			state.error.transactions = null;
		},
		fetchTransactionsSuccess: (state, action: PayloadAction<Transaction[]>) => {
			state.loading.transactions = false;
			state.data.transactions = action.payload;
		},
		fetchTransactionsFailure: (state, action: PayloadAction<string>) => {
			state.loading.transactions = false;
			state.error.transactions = action.payload;
		},
		// stats
		fetchStatsRequest: (state) => {
			state.loading.transactionStats = true;
			state.error.transactionStats = null;
		},
		fetchStatsSuccess: (state, action: PayloadAction<TransactionStats>) => {
			state.loading.transactionStats = false;
			state.data.transactionStats = action.payload;
		},
		fetchStatsFailure: (state, action: PayloadAction<string>) => {
			state.loading.transactionStats = false;
			state.error.transactionStats = action.payload;
		},
		//
		fetchMonitoredWalletsRequest: (state) => {
			state.loading.wallets = true;
			state.error.wallets = null;
		},
		fetchMonitoredWalletsSuccess: (
			state,
			action: PayloadAction<PieChartData[]>
		) => {
			state.loading.wallets = false;
			state.data.wallets = action.payload;
		},
		fetchMonitoredWalletsFailure: (state, action: PayloadAction<string>) => {
			state.loading.wallets = false;
			state.error.wallets = action.payload;
		},
		//
		fetchVolumeRequest: (state) => {
			state.loading.volume = true;
			state.error.volume = null;
		},
		fetchVolumeSuccess: (state, action: PayloadAction<PieChartData[]>) => {
			state.loading.volume = false;
			state.data.volume = action.payload;
		},
		fetchVolumeFailure: (state, action: PayloadAction<string>) => {
			state.loading.volume = false;
			state.error.volume = action.payload;
		},
	},
});

export const dashboardActions = {
	fetchTransactionsRequest: dashboardSlice.actions.fetchTransactionsRequest,
	fetchTransactionsSuccess: dashboardSlice.actions.fetchTransactionsSuccess,
	fetchTransactionsFailure: dashboardSlice.actions.fetchTransactionsFailure,
	//
	fetchStatsRequest: dashboardSlice.actions.fetchStatsRequest,
	fetchStatsSuccess: dashboardSlice.actions.fetchStatsSuccess,
	fetchStatsFailure: dashboardSlice.actions.fetchStatsFailure,
	//
	fetchMonitoredWalletsRequest:
		dashboardSlice.actions.fetchMonitoredWalletsRequest,
	fetchMonitoredWalletsSuccess:
		dashboardSlice.actions.fetchMonitoredWalletsSuccess,
	fetchMonitoredWalletsFailure:
		dashboardSlice.actions.fetchMonitoredWalletsFailure,
	//
	fetchVolumeRequest: dashboardSlice.actions.fetchVolumeRequest,
	fetchVolumeSuccess: dashboardSlice.actions.fetchVolumeSuccess,
	fetchVolumeFailure: dashboardSlice.actions.fetchVolumeFailure,
};

// Reducer
export default dashboardSlice.reducer;
