import { PieChartData } from '../components/organisms/Chart/PieChart';

export type TotalAmountType = {
	from: {
		value: number;
		unit: string;
	};
	to: {
		value: number;
		unit: string;
	};
};

export type WalletDataType = { code: string; wallet: string; walletId: number };

export type Transaction = {
	id: number;
	dateTime: string;
	from: WalletDataType;
	proxy: string;
	to: WalletDataType;
	hash: string;
	totalAmount: TotalAmountType;
	riskLevel: string;
	riskFactor: string;
	status: string;
	moneyBackGuarantee: string;
};

export type StatsByStatus = {
	status: string;
	value: number;
};

export type TransactionStats = {
	byRiskLevel: PieChartData[];
	byStatus: PieChartData[];
};

export enum TransactionStatus {
	transferring = 'transferring',
	rejected = 'rejected',
	expired = 'expired',
	completed = 'completed',
	paused = 'paused',
	unknown = 'unknown',
}

export enum BackGuarantee {
	yes = 'yes',
	no = 'no',
}

export enum RiskLvl {
	low = 'low',
	medium = 'medium',
	high = 'high',
	critical = 'critical',
}
