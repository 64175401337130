import { riskLevelColorMap } from '../pages/wallets/WalletsPage';
import WalletListItem from '../molecules/WalletListItem';
import { Wallet } from '../../types/wallets';

import { cn } from '../../libs/cn';

interface WalletsListProps {
	data: Wallet[];
	goToWalletDetails: (id: number) => void;
}

const header = [
	{ name: 'Name', classNames: 'max-w-16 xl:max-w-20' },
	{ name: 'Address', classNames: 'max-w-[120px] xl:max-w-[170px]' },
	{ name: 'Currency' },
	{ name: 'Risk Level', classNames: 'max-w-28' },
	{ name: 'Risk Factors' },
	{ name: 'Actions', classNames: 'flex justify-start max-w-56' },
];

const WalletsList: React.FC<WalletsListProps> = ({
	data,
	goToWalletDetails,
}) => {
	return (
		<div className="grid gap-4">
			<div className="items-center gap-4 sm:justify-between px-4 flex-wrap hidden lg:flex">
				{header.map((item) => (
					<p
						key={item.name}
						className={cn(
							'flex-1 font-bold whitespace-nowrap',
							item.classNames
						)}
					>
						{item.name}
					</p>
				))}
			</div>

			{data?.map((wallet) => {
				const factorBg = riskLevelColorMap[wallet?.riskLevel] || 'bg-gray-100';

				return (
					<WalletListItem
						key={wallet?.id}
						item={wallet}
						bgColor={factorBg}
						goToWalletDetails={goToWalletDetails}
					/>
				);
			})}
		</div>
	);
};

export default WalletsList;
