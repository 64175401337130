import { useMemo } from 'react';

import { reorderProperties } from '../../../utils/object';

import { Transaction } from '../../../types/transaction';

type StatusBarProps = {
	data: Transaction[];
};

const countStatuses = (arr: any[], field: string) => {
	const riskLevelCounts: Record<string, number> = {
		rejected: 0,
		pending: 0,
		completed: 0,
	};

	arr.forEach((el) => {
		const status = el[field];
		if (Object.prototype.hasOwnProperty.call(riskLevelCounts, status)) {
			riskLevelCounts[status]++;
		}
	});

	return riskLevelCounts;
};

const colors: Record<string, string> = {
	rejected: 'bg-gradient-high',
	pending: 'bg-grey-500',
	completed: 'bg-gradient-primary',
};

const StatusesStats: React.FC<StatusBarProps> = ({ data }) => {
	const statusesCount = useMemo(() => countStatuses(data, 'status'), [data]);

	const statuses = useMemo(
		() =>
			reorderProperties(statusesCount, ['rejected', 'pending', 'completed']),
		[statusesCount]
	);

	return (
		<div className="flex gap-2 lg:gap-5 flex-wrap justify-center">
			{Object.entries(statuses || {}).map(([key, value]) => (
				<div key={key} className="flex gap-2 items-center">
					<div className={`w-5 h-5 rounded ${colors[key]}`}></div>
					<span className="capitalize">{key}</span>{' '}
					<span className="font-bold">{value}</span>
				</div>
			))}
		</div>
	);
};

export { StatusesStats };
