import { Card, CardContent, CardFooter, CardHeader } from '../atoms/Card';
import { Badge } from '../atoms/Badge';
import { Button } from '../atoms/Button';
import ResponsiveIcon from '../atoms/Icon';
import { iconMap } from '../../theme/Icons';
import { Wallet } from '../../types/wallets';

import { cn } from '../../libs/cn';

interface WalletCardProps {
	item: Wallet;
	bgColor?: string;
	goToWalletDetails: (id: number) => void;
}

const WalletCard: React.FC<WalletCardProps> = ({
	item,
	bgColor,
	goToWalletDetails,
}) => {
	return (
		<Card
			key={item.id}
			className="flex flex-col h-full hover:shadow-hover-lg hover:cursor-pointer transition-all"
			onClick={() => goToWalletDetails(item.id)}
		>
			<CardHeader className="mb-2">
				<div className="flex gap-2 justify-between items-center flex-wrap">
					<div className="flex gap-2">
						{item?.cryptocurrencies.map((item) => (
							<div key={item?.code} className="bg-grey-300 p-2 rounded-full">
								<ResponsiveIcon
									icon={iconMap[item.code.toLowerCase()]}
									className="w-5 h-5"
								/>
							</div>
						))}
					</div>
					<div className="flex items-start lg:items-center gap-1 2xl:gap-2 flex-col 2xl:flex-row">
						<p className="font-bold">Risk Level</p>
						<Badge className="items-center" variant={item?.riskLevel}>
							{item?.riskLevel}
						</Badge>
					</div>
				</div>
			</CardHeader>

			<CardContent className="gap-2 flex flex-col flex-grow">
				<p className="text-xl font-bold">{item?.name}</p>
				<p className="truncate">{item?.address}</p>
				{item?.riskFactors.length > 0 && (
					<div className="flex gap-2 items-center flex-wrap">
						<p className="font-bold">Risk Factors</p>
						<ul className="flex gap-2 flex-wrap">
							{item?.riskFactors.map((factor, index) => (
								<Badge
									key={index}
									className={cn('text-black px-2 py-0 min-w-20', bgColor)}
								>
									{factor}
								</Badge>
							))}
						</ul>
					</div>
				)}
			</CardContent>

			<CardFooter className="flex justify-between mt-6">
				<Button
					disabled={!item.canSend}
					iconPosition="right"
					icon={<ResponsiveIcon icon={iconMap.arrow} className="w-4 h-5" />}
				>
					Go to Send
				</Button>

				<Button
					size={'icon'}
					variant={'icon'}
					icon={<ResponsiveIcon icon={iconMap.delete} className="w-4 h-5" />}
					className="bg-transparent border-none"
				/>
			</CardFooter>
		</Card>
	);
};

export default WalletCard;
