import {
	BackGuarantee,
	RiskLvl,
	TransactionStatus,
} from '../../types/transaction';

export const _transactions = [
	{
		id: 0,
		dateTime: 'Tue, 3 Apr 2024 17:32:00 GMT',
		from: {
			code: 'bc1d4x0w7v9h8u4ks8lw5j7yq3h6p9lz2d',
			wallet: 'Wallet 3',
			walletId: 3,
		},
		proxy: 'bc1d4p6z7w3m8k5n2r1a9t4j2y0v8xqj5',
		to: {
			code: 'bc1d4m3b8n7p0k5h6r2v4j9t3l2a1x8zyw',
			wallet: 'Wallet 4',
			walletId: 4,
		},
		hash: 'b1740492406a5a50',
		totalAmount: {
			from: {
				value: 3420,
				unit: 'BTC',
			},
			to: {
				value: 232170,
				unit: 'USD',
			},
		},
		riskLevel: RiskLvl.medium,
		riskFactor: 'Multiple (8)',
		status: TransactionStatus.transferring,
		moneyBackGuarantee: BackGuarantee.yes,
	},
	{
		id: 1,
		dateTime: 'Mon, 22 Apr 2024 16:04:09 GMT',
		from: {
			code: 'bc1d4p6z7w3m8k5n2r1a9t4j2y0v8xqj5',
			wallet: 'Wallet 4',
			walletId: 4,
		},
		proxy: 'bc1d4m3b8n7p0k5h6r2v4j9t3l2a1x8zyw',
		to: {
			code: 'bc1d4p6z7w3m8k5n2r1a9t4j2y0v8xqj5',
			wallet: 'Wallet 3',
			walletId: 3,
		},
		hash: '147c2144614ce975',
		totalAmount: {
			from: {
				value: 3420,
				unit: 'BTC',
			},
			to: {
				value: 232170,
				unit: 'USD',
			},
		},
		riskLevel: RiskLvl.critical,
		riskFactor: 'Multiple (8)',
		status: TransactionStatus.rejected,
		moneyBackGuarantee: BackGuarantee.yes,
	},
	{
		id: 2,
		dateTime: 'Wed, 08 May 2024 17:38:04 GMT',
		from: {
			code: 'bc1d4n5t8v2r0p6k4a7m3j9y1x2z4w8q7',
			wallet: 'Wallet 4',
			walletId: 4,
		},
		proxy: 'bc1d4p6z7w3m8k5n2r1a9t4j2y0v8xqj5',
		to: {
			code: 'bc1d4m3b8n7p0k5h6r2v4j9t3l2a1x8zyw',
			wallet: 'Wallet 2',
			walletId: 2,
		},
		hash: 'bfc2288eb1f0589c',
		totalAmount: {
			from: {
				value: 3420,
				unit: 'BTC',
			},
			to: {
				value: 232170,
				unit: 'USD',
			},
		},
		riskLevel: RiskLvl.low,
		riskFactor: 'Multiple (8)',
		status: TransactionStatus.transferring,
		moneyBackGuarantee: BackGuarantee.no,
	},
	{
		id: 3,
		dateTime: 'Thu, 06 Jul 2023 14:24:24 GMT',
		from: {
			code: 'bc1d4m3b8n7p0k5h6r2v4j9t3l2a1x8zyw',
			wallet: 'Wallet 2',
			walletId: 2,
		},
		proxy: 'bc1d4m3b8n7p0k5h6r2v4j9t3l2a1x8zyw',
		to: {
			code: 'bc1d4n5t8v2r0p6k4a7m3j9y1x2z4w8q7',
			wallet: 'Wallet 1',
			walletId: 1,
		},
		hash: '83c5594565401d4b',
		totalAmount: {
			from: {
				value: 3420,
				unit: 'BTC',
			},
			to: {
				value: 232170,
				unit: 'USD',
			},
		},
		riskLevel: RiskLvl.medium,
		riskFactor: 'Multiple (8)',
		status: TransactionStatus.expired,
		moneyBackGuarantee: BackGuarantee.yes,
	},
	{
		id: 4,
		dateTime: 'Sun, 09 Jul 2023 20:07:49 GMT',
		from: {
			code: 'bc1d4n5t8v2r0p6k4a7m3j9y1x2z4w8q7',
			wallet: 'Wallet 4',
			walletId: 4,
		},
		proxy: 'bc1d4x0w7v9h8u4ks8lw5j7yq3h6p9lz2d',
		to: {
			code: 'bc1d4s9z5r2m8v0t6q1a3w7j9e4k2y8xpq',
			wallet: 'Wallet 2',
			walletId: 2,
		},
		hash: 'd0c953434c3bc899',
		totalAmount: {
			from: {
				value: 3420,
				unit: 'BTC',
			},
			to: {
				value: 232170,
				unit: 'USD',
			},
		},
		riskLevel: RiskLvl.high,
		riskFactor: 'Multiple (8)',
		status: TransactionStatus.transferring,
		moneyBackGuarantee: BackGuarantee.yes,
	},
	{
		id: 5,
		dateTime: 'Sat, 07 Jan 2023 15:53:58 GMT',
		from: {
			code: 'bc1d4m3b8n7p0k5h6r2v4j9t3l2a1x8zyw',
			wallet: 'Wallet 4',
			walletId: 4,
		},
		proxy: 'bc1d4n5t8v2r0p6k4a7m3j9y1x2z4w8q7',
		to: {
			code: 'bc1d4s9z5r2m8v0t6q1a3w7j9e4k2y8xpq',
			wallet: 'Wallet 2',
			walletId: 2,
		},
		hash: '5aa853adedc1b00f',
		totalAmount: {
			from: {
				value: 3420,
				unit: 'BTC',
			},
			to: {
				value: 232170,
				unit: 'USD',
			},
		},
		riskLevel: RiskLvl.medium,
		riskFactor: 'Multiple (8)',
		status: TransactionStatus.completed,
		moneyBackGuarantee: BackGuarantee.yes,
	},
	{
		id: 6,
		dateTime: 'Fri, 30 Jun 2023 14:24:00 GMT',
		from: {
			code: 'bc1d4m3b8n7p0k5h6r2v4j9t3l2a1x8zyw',
			wallet: 'Wallet 1',
			walletId: 1,
		},
		proxy: 'bc1d4s9z5r2m8v0t6q1a3w7j9e4k2y8xpq',
		to: {
			code: 'bc1d4m3b8n7p0k5h6r2v4j9t3l2a1x8zyw',
			wallet: 'Wallet 3',
			walletId: 3,
		},
		hash: '76306c229f3a59c1',
		totalAmount: {
			from: {
				value: 3420,
				unit: 'BTC',
			},
			to: {
				value: 232170,
				unit: 'USD',
			},
		},
		riskLevel: RiskLvl.high,
		riskFactor: 'Multiple (8)',
		status: TransactionStatus.unknown,
		moneyBackGuarantee: BackGuarantee.yes,
	},
	{
		id: 7,
		dateTime: 'Mon, 06 Feb 2023 08:01:01 GMT',
		from: {
			code: 'bc1d4m3b8n7p0k5h6r2v4j9t3l2a1x8zyw',
			wallet: 'Wallet 3',
			walletId: 3,
		},
		proxy: 'bc1d4p6z7w3m8k5n2r1a9t4j2y0v8xqj5',
		to: {
			code: 'bc1d4n5t8v2r0p6k4a7m3j9y1x2z4w8q7',
			wallet: 'Wallet 2',
			walletId: 2,
		},
		hash: '5e96437be81bfa5d',
		totalAmount: {
			from: {
				value: 3420,
				unit: 'BTC',
			},
			to: {
				value: 232170,
				unit: 'USD',
			},
		},
		riskLevel: RiskLvl.critical,
		riskFactor: 'Multiple (8)',
		status: TransactionStatus.rejected,
		moneyBackGuarantee: BackGuarantee.yes,
	},
	{
		id: 8,
		dateTime: 'Wed, 09 Aug 2023 01:25:00 GMT',
		from: {
			code: 'bc1d4x0w7v9h8u4ks8lw5j7yq3h6p9lz2d',
			wallet: 'Wallet 4',
			walletId: 4,
		},
		proxy: 'bc1d4m3b8n7p0k5h6r2v4j9t3l2a1x8zyw',
		to: {
			code: 'bc1d4s9z5r2m8v0t6q1a3w7j9e4k2y8xpq',
			wallet: 'Wallet 1',
			walletId: 1,
		},
		hash: '59362e6397ccb53e',
		totalAmount: {
			from: {
				value: 3420,
				unit: 'BTC',
			},
			to: {
				value: 232170,
				unit: 'USD',
			},
		},
		riskLevel: RiskLvl.low,
		riskFactor: 'Multiple (8)',
		status: TransactionStatus.transferring,
		moneyBackGuarantee: BackGuarantee.no,
	},
	{
		id: 9,
		dateTime: 'Wed, 07 Feb 2024 13:25:56 GMT',
		from: {
			code: 'bc1d4m3b8n7p0k5h6r2v4j9t3l2a1x8zyw',
			wallet: 'Wallet 4',
			walletId: 4,
		},
		proxy: 'bc1d4s9z5r2m8v0t6q1a3w7j9e4k2y8xpq',
		to: {
			code: 'bc1d4m3b8n7p0k5h6r2v4j9t3l2a1x8zyw',
			wallet: 'Wallet 2',
			walletId: 2,
		},
		hash: 'd1f0b4a9db050261',
		totalAmount: {
			from: {
				value: 3420,
				unit: 'BTC',
			},
			to: {
				value: 232170,
				unit: 'USD',
			},
		},
		riskLevel: RiskLvl.low,
		riskFactor: 'Multiple (8)',
		status: TransactionStatus.completed,
		moneyBackGuarantee: BackGuarantee.no,
	},
];
