import { useCallback } from 'react';

import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { WalletsState, walletsActions } from './slice';

type WalletsServiceOperators = WalletsState & {
	getWallets: () => void;
};

const useWalletsService = (): Readonly<WalletsServiceOperators> => {
	const dispatch = useAppDispatch();
	const walletsState = useAppSelector((state) => state.wallets);

	return {
		data: walletsState.data,
		loading: walletsState.loading,
		error: walletsState.error,
		getWallets: useCallback(() => {
			dispatch(walletsActions.fetchWalletsRequest());
		}, [dispatch]),
	};
};

export default useWalletsService;
