import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import { User } from '../../types/auth';

export interface AuthState {
	data: {
		user: User | null;
		isAuthenticated: boolean;
		isVerified: boolean;
	};
	loading: {
		login: boolean;
		verifyLogin: boolean;
	};
	error: {
		login: string | null;
		verifyLogin: string | null;
	};
}

const initialState: AuthState = {
	data: {
		user: null,
		isAuthenticated: false,
		isVerified: false,
	},
	loading: {
		login: false,
		verifyLogin: false,
	},
	error: {
		login: null,
		verifyLogin: null,
	},
};

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		loginRequest: (
			state,
			_action: PayloadAction<{ email: string; password: string }>
		) => {
			state.loading.login = true;
			state.error.login = null;
		},
		loginSuccess: (state, _action: PayloadAction<string>) => {
			state.loading.login = false;
			state.data.isAuthenticated = true;
		},
		loginFailure: (state, action: PayloadAction<string>) => {
			state.loading.login = false;
			state.error.login = action.payload;
		},
		//
		verifyLoginRequest: (state, _action: PayloadAction<string[]>) => {
			state.loading.verifyLogin = true;
			state.error.verifyLogin = null;
		},
		verificationSuccess: (state, _action: PayloadAction<User>) => {
			state.loading.verifyLogin = false;
			state.data.isVerified = true;
		},
		verificationFailure: (state, action: PayloadAction<string>) => {
			state.loading.verifyLogin = false;
			state.error.verifyLogin = action.payload;
		},
		logout: (state) => {
			state.data.user = null;
			state.data.isAuthenticated = false;
			state.data.isVerified = false;
		},
		clearError: (state) => {
			state.error.login = '';
			state.error.verifyLogin = '';
		},
	},
});

export const authActions = {
	loginRequest: authSlice.actions.loginRequest,
	loginSuccess: authSlice.actions.loginSuccess,
	loginFailure: authSlice.actions.loginFailure,
	//
	verificationRequest: authSlice.actions.verifyLoginRequest,
	verificationSuccess: authSlice.actions.verificationSuccess,
	verificationFailure: authSlice.actions.verificationFailure,
	logout: authSlice.actions.logout,
	clearError: authSlice.actions.clearError,
};

// Reducer
export default authSlice.reducer;
