import { useState } from 'react';

import { Button } from '../../atoms/Button';
import {
	Dialog,
	DialogContent,
	DialogFooter,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from '../../atoms/Dialog';
import ResponsiveIcon from '../../atoms/Icon';
import { Input } from '../../atoms/Input';
import BaseMultiSelect from '../../organisms/BaseMultiSelect';
import { iconMap } from '../../../theme/Icons';
import { Label } from '../../atoms/Label';

import { _cryptocurrencies } from '../../../_mock';

const AddNewWallet: React.FC = () => {
	const [isOpen, setIsOpen] = useState(false);
	const [selectedCurrency, setSelectedCurrency] = useState<string[]>([]);

	return (
		<Dialog open={isOpen} onOpenChange={setIsOpen}>
			<DialogTrigger asChild>
				<Button
					className="p-10 shadow-lg"
					variant="icon"
					size="icon"
					icon={
						<ResponsiveIcon
							icon={iconMap.plus}
							className="text-black w-8 h-8"
						/>
					}
				/>
			</DialogTrigger>

			<DialogContent className="sm:max-w-[600px] p-11 gap-8 md:gap-12">
				<DialogHeader>
					<DialogTitle className="flex flex-col sm:flex-row gap-2 sm:gap-5 items-center text-2xl font-bold text-center border-b border-[#A7D0C7] pb-3">
						<ResponsiveIcon
							icon={iconMap.wallets}
							color={'var(--color-primary-300)'}
							size={42}
						/>
						<p>Add New Wallet</p>
					</DialogTitle>
				</DialogHeader>

				<div className="flex flex-col gap-2">
					<Label>Address</Label>
					<Input
						autoComplete="off"
						placeholder="bc1d42UNb54eBiGm0qEM0h6r2h8n532to9jtp186ns"
					/>
				</div>

				<div className="sm:grid grid-cols-3 gap-8 items-center flex flex-col">
					<div className="col-span-2 flex flex-col gap-2 w-full sm:w-auto">
						<Label>Nick Name</Label>
						<Input autoComplete="off" placeholder="Kucoin" />
					</div>

					<div className="col-span-1 flex flex-col gap-2 w-full sm:w-auto">
						<Label>Chain</Label>
						<BaseMultiSelect
							selectedValue={selectedCurrency}
							setSelectedValue={setSelectedCurrency}
							placeholder="USD, BTC..."
							className="w-auto"
							values={_cryptocurrencies.map(
								(item) => item.cryptocurrency as string
							)}
						/>
					</div>
				</div>

				<DialogFooter className="mt-6">
					<Button type="submit" className="text-lg">
						Add Wallet
					</Button>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
};

export default AddNewWallet;
