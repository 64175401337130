import { Avatar, AvatarFallback, AvatarImage } from '../../atoms/Avatar';
import placeholderImage from '../../../assets/images/placeholder-image-1.png';
import ResponsiveIcon from '../../atoms/Icon';
import { iconMap } from '../../../theme/Icons';
import { Button } from '../../atoms/Button';
import { cn } from '../../../libs/cn';

type HeaderProps = {
	title?: string;
	className?: string;
};

const Header: React.FC<HeaderProps> = ({ title, className }) => {
	return (
		<div className="flex gap-x-4 p-4 font-bold ml-auto justify-between w-full items-center">
			<p className="font-bold sm:text-lg md:text-xl">{title}</p>

			<div className="flex gap-2 sm:gap-4 md:gap-x-6 items-center">
				<Button
					variant={'icon'}
					size={'icon'}
					className={cn('border-none bg-white', className)}
				>
					<ResponsiveIcon
						icon={iconMap.bell}
						badgeStyles="bg-critical"
						badgeText={1}
					/>
				</Button>
				<Avatar className="w-11 h-11 sm:w-auto sm:h-auto">
					<AvatarImage src={placeholderImage} />
					<AvatarFallback className="bg-gray-200">CN</AvatarFallback>
				</Avatar>
			</div>
		</div>
	);
};

export { Header };
