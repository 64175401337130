import { useRef, useState } from 'react';
import {
	PieChart,
	Pie,
	Cell,
	Legend,
	Tooltip,
	ResponsiveContainer,
	Sector,
} from 'recharts';
import { ContentType } from 'recharts/types/component/DefaultLegendContent';
import BaseLegend from './legends/BaseLegend';
import BaseTooltip from './tooltips/BaseTooltip';

export type PieChartData = {
	name: string;
	value: number;
	color: string[];
	group?: string;
};

type BasePieChartProps = {
	renderLegendContent?: ContentType;
	data: PieChartData[];
	groupStyle?: string;
};

const createGradient = (colors: any, id: any) => {
	if (colors.length === 1) {
		return colors[0];
	}

	return (
		<linearGradient id={id} x1="0%" y1="0%" x2="100%" y2="100%">
			{colors.map((color: any, index: any) => (
				<stop
					key={index}
					offset={`${(index / (colors.length - 1)) * 100}%`}
					style={{ stopColor: color, stopOpacity: 1 }}
				/>
			))}
		</linearGradient>
	);
};

function BasePieChart({ data, groupStyle }: BasePieChartProps) {
	const containerRef = useRef<HTMLDivElement | null>(null);
	const [activeIndex, setActiveIndex] = useState(-1);
	const groupedData = data?.reduce((acc: any, cur: any) => {
		if (!acc[cur.title]) {
			acc[cur.title] = [];
		}
		acc[cur.title].push(cur);
		return acc;
	}, {});

	return (
		<div ref={containerRef}>
			<ResponsiveContainer width="100%" height={300}>
				<PieChart className="flex justify-between items-center w-full gap-4 mx-auto">
					<defs>
						{data?.map((entry, index) =>
							entry?.color?.length > 1
								? createGradient(entry.color, `grad${index}`)
								: null
						)}
					</defs>
					<Pie
						data={data}
						cx="50%"
						cy="50%"
						outerRadius={68}
						innerRadius={48}
						dataKey="value"
						activeIndex={activeIndex}
						animationDuration={550}
						animationEasing="ease-in-out"
						onMouseEnter={(_, index) => setActiveIndex(index)}
						onMouseLeave={() => setActiveIndex(-1)}
						activeShape={(props: any) => (
							<Sector {...props} outerRadius={props.outerRadius + 10} />
						)}
					>
						{data?.map((entry, index) => (
							<Cell
								key={`cell-${index}`}
								fill={
									entry?.color?.length > 1
										? `url(#grad${index})`
										: entry?.color[0]
								}
							/>
						))}
					</Pie>
					<Legend
						content={
							<BaseLegend payload={groupedData} groupStyle={groupStyle} />
						}
						align={'right'}
						layout="vertical"
						verticalAlign={'middle'}
					/>
					<Tooltip content={<BaseTooltip />} />
				</PieChart>
			</ResponsiveContainer>
		</div>
	);
}

export { BasePieChart };
