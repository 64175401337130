import {
	HoverCard,
	HoverCardContent,
	HoverCardTrigger,
} from '../../atoms/HoverCard';
import { iconMap } from '../../../theme/Icons';
import ResponsiveIcon from '../../atoms/Icon';
import { Badge } from '../../atoms/Badge';

const risks = ['Gaming', 'High-risk exchange', 'High-risk'];

const RisksPopover: React.FC = () => {
	return (
		<HoverCard openDelay={0}>
			<HoverCardTrigger asChild className="cursor-pointer">
				<div className="w-fit py-0.5 inline-flex items-center gap-2">
					<span>Multiple Risks</span>
					<ResponsiveIcon icon={iconMap.info} />
				</div>
			</HoverCardTrigger>

			<HoverCardContent className="max-w-56 bg-black">
				<div className="flex flex-col gap-2">
					{risks.map((risk, index) => (
						<Badge className="bg-[#E9B1B1] text-black" key={index}>
							{risk}
						</Badge>
					))}
				</div>
			</HoverCardContent>
		</HoverCard>
	);
};

export default RisksPopover;
