import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { Wallet } from '../../types/wallets';

export type WalletsState = {
	data: {
		wallets: Wallet[];
	};
	loading: {
		wallets: boolean;
	};
	error: {
		wallets: string | null;
	};
};

const initialState: WalletsState = {
	data: {
		wallets: [],
	},
	loading: {
		wallets: false,
	},
	error: {
		wallets: null,
	},
};

const walletsSlice = createSlice({
	name: 'wallets',
	initialState,
	reducers: {
		fetchWalletsRequest: (state) => {
			state.loading.wallets = true;
			state.error.wallets = null;
		},
		fetchWalletsSuccess: (state, action: PayloadAction<Wallet[]>) => {
			state.loading.wallets = false;
			state.data.wallets = action.payload;
		},
		fetchWalletsFailure: (state, action: PayloadAction<string>) => {
			state.loading.wallets = false;
			state.error.wallets = action.payload;
		},
	},
});

export const walletsActions = {
	fetchWalletsRequest: walletsSlice.actions.fetchWalletsRequest,
	fetchWalletsSuccess: walletsSlice.actions.fetchWalletsSuccess,
	fetchWalletsFailure: walletsSlice.actions.fetchWalletsFailure,
};

// Reducer
export default walletsSlice.reducer;
