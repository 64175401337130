import { useEffect, useState } from 'react';

import { TransactionTable } from '../specialized/TransactionTable/TransactionTable';
import { RiskLevelStats } from '../molecules/RiskLevelStats';
import { transactionColumns } from '../specialized/TransactionTable/columns';
import DateFilterPanel, { Range } from '../specialized/DateFilterPanel';
import useDashboardDataService from '../../controllers/dashboard/service';
import BaseMultiSelect from '../organisms/BaseMultiSelect';
import { Input } from '../atoms/Input';
import { Button } from '../atoms/Button';
import ResponsiveIcon from '../atoms/Icon';

import { iconMap } from '../../theme/Icons';

import { _cryptocurrencies, statuses } from '../../_mock';
import { Progress } from '../atoms/Progress';

const TransactionsPage: React.FC = () => {
	const [selectedRange, setSelectedRange] = useState<Range>(Range.today);
	const [selectedCurrency, setSelectedCurrency] = useState<string[]>([]);
	const [selectedStatus, setSelectedStatus] = useState<string[]>([]);

	const { data, error, loading, loadTransactions } = useDashboardDataService();

	useEffect(() => {
		loadTransactions();
	}, [loadTransactions]);

	const handleFilterChange = (range: Range) => {
		setSelectedRange(range);
	};

	if (loading.transactions) {
		return (
			<div className="flex justify-center items-center min-h-[600px]">
				<Progress />
			</div>
		);
	}

	return (
		<div className="flex flex-col gap-6 flex-1">
			<div className="flex items-center justify-between">
				<DateFilterPanel
					selectedRange={selectedRange}
					onFilterChange={handleFilterChange}
				/>
			</div>

			<div className="flex flex-col gap-5 h-full">
				<div className="flex gap-4 items-center justify-between flex-col lg:flex-row">
					<div className="flex gap-4 2xl:gap-8 items-center flex-col 2xl:flex-row">
						<RiskLevelStats data={data.transactions} />

						<div className="flex gap-4 lg:gap-6 justify-center flex-wrap lg:flex-nowrap lg:flex-row">
							<div className="flex gap-3 items-center">
								<span className="font-bold">Status</span>
								<BaseMultiSelect
									selectedValue={selectedStatus}
									setSelectedValue={setSelectedStatus}
									triggerClassName="border-black"
									placeholder="Status"
									values={statuses.map((item) => item)}
								/>
							</div>

							<div className="flex gap-3 items-center">
								<span className="font-bold">Currency</span>
								<BaseMultiSelect
									selectedValue={selectedCurrency}
									triggerClassName="border-black"
									setSelectedValue={setSelectedCurrency}
									placeholder="USD, BTC..."
									values={_cryptocurrencies.map(
										(item) => item.cryptocurrency as string
									)}
								/>
							</div>

							<Input
								icon={iconMap.search}
								placeholder="Search"
								className="bg-grey-50 border-none"
							/>
						</div>
					</div>

					<Button
						onClick={() => {}}
						iconPosition="left"
						icon={<ResponsiveIcon icon={iconMap.report} className="h-5 w-5" />}
						className="bg-transparent border-none"
					>
						Report
					</Button>
				</div>

				<div className="ml-[-12px]">
					<TransactionTable
						columns={transactionColumns}
						data={data.transactions}
						isLoading={loading.transactions}
						error={error.transactions}
						type="extended"
					/>

					{data.transactions.length > 0 && (
						<div className="mt-4 flex flex-col gap-2 items-center">
							<p className="text-grey-600">1-10 of 55</p>
							<Button size={'sm'} variant={'outline'}>
								Load More
							</Button>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default TransactionsPage;
